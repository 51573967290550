.service-card {
    width: 100%;
    height: auto;
    border-radius: 0.75rem;
    box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.15);
    padding: 1.25rem 1.25rem 0.75rem 1.25rem;
    margin-right: 3rem;
    margin-bottom: 2rem;
}

.service-card .services-left {
    border-right: 0.1rem solid rgba(159, 159, 159, 1);
    padding-right: 1rem !important;
}

.sent-popup {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    margin-top: 14px;
}

.company_visit {
    text-decoration: none;
    color: inherit !important;
}

.top-layer {
    height: 4rem;
}

.top-layer .d-flex figure img {
    height: 3.25rem;
    width: 3.25rem;
    border-radius: 50%;
}

.top-layer .d-flex .service-card-details {
    max-height: 4rem;
}

.top-layer .d-flex .service-card-details h3 {
    font-size: 1.25rem;
    font-weight: bold;
    padding: 0 0 0.2rem 0;
}

.top-layer .d-flex .service-card-details .reviews-count {
    color: #03a9f4;
    font-size: 1rem;
    font-weight: bold;
    padding-left: 0.5rem;
    font-size: 0.9rem;
}

.service-card .reviews-section {
    padding: 1rem 0;
    border-bottom: 0px;
}

.service-card .reviews-section .reviews-text {
    font-size: 0.85rem;
    color: #4e4e4e;
}

.service-card .reviews-section .review-by {
    font-weight: bold;
    color: #4e4e4e;
    font-size: 0.9rem;
}
.review-by:hover {
    text-decoration: underline;
}
.service-card .reviews-section h6 {
    color: #03a9f4;
    font-weight: 600;
    cursor: pointer;
    font-size: 0.9rem;
    margin-top: 0.6rem;
}

.service-tags {
    border-top: 0.1rem solid #9f9f9f;
    margin-top: 5px;
    padding-top: 1.2rem;
    max-height: 6.2rem;
    overflow: hidden;
}

.service-tags div {
    margin-bottom: 1.25rem;
    display: inline-block;
    margin-right: 0.75rem;
}

.service-tags span {
    background-color: #03a9f4;
    border-radius: 1rem;
    padding: 0.4rem 1.25rem;
    color: white;
    text-align: center;
    font-size: 0.9rem;
}

.action-items .d-flex {
    flex-direction: column;
    height: 100%;
    justify-content: space-around;
    /* padding: 0px 8px 0px 10px; */
}

.action-items .d-flex .action-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e1f5fe;
    height: 2.5rem;
    width: 2.5rem;
    margin: auto;
    padding: 0.5rem;
    border-radius: 0.6rem;
}

.action-items .d-flex .action-icon svg {
    font-size: 1.8rem;
    color: #007893;
    cursor: pointer;
}

.company-card-name {
    display: flex;
}

.company-card-name h3 {
    padding: 0;
    margin: 0;
}
.company-card-name img {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0.6rem;
    height: 22px;
    width: 22px;
}

.about_text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}

/* Media Query */

@media (max-width: 767px) {
    .service-card {
        padding-bottom: 1.25rem;
    }
    .services-left {
        border-right: none;
        padding-right: 0 !important;
    }
    .service-tags {
        max-height: 9.2rem;
        border-bottom: 0.1rem solid #9f9f9f;
    }
    .action-items .d-flex {
        margin-top: 0.5rem;
        flex-direction: row !important;
    }
    .services-left {
        border-right: none;
        padding-right: 0 !important;
    }
    .service-tags {
        max-height: 9.2rem;
        border-bottom: 0.1rem solid #9f9f9f;
    }
    .action-items .d-flex {
        margin-top: 0.5rem;
        flex-direction: row !important;
    }
}
.companyDetail {
    width: 615px;
    height: 36px;
    align-items: center;
    display: flex;
    border-radius: 3px;
    margin-bottom: 10px;
    padding-left: 10px;
    border: 1px solid #d6d6d6;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #7c7c7c;
}

.Touch-body .top-heading {
    display: flex;
    align-items: center;
    margin-bottom: 22px;
}

.Touch-body .top-heading .icon-wrapper {
    border: 1px solid #455a64;
    padding: 0.5rem;
    font-size: 1.6rem;
    border-radius: 50%;
}
.Touch-body {
    padding-bottom: 1rem;
}
.Touch-body .top-heading .icon-wrapper svg {
    color: #455a64;
}
.Touch-body .top-heading h1 {
    display: flex;
    align-items: center;
    margin-left: 1rem;
    color: #5b5b5b;
    font-size: 1.5rem;
}

.Touch-body .get-free-ad-credit {
    color: #959595;
    font-size: 0.9rem;
    margin-top: 0.8rem;
}
.Touch-modal .WAMuiChipInput-inputRoot-2 {
    padding: 0 !important;
}
.Touch-modal .WAMuiChipInput-root-1 {
    border: none !important;
    min-width: 300px;
    padding: 0 !important;
    margin: 0 !important;
}

.Touch-modal .WAMuiChipInput-root-1 label {
    display: none;
}

.Touch-modal .WAMuiChipInput-root-1 input {
    padding: 0.3rem 1rem;
}

.Touch-modal .WAMuiChipInput-inputRoot-2.WAMuiChipInput-outlined-5 {
    padding-left: 0.5rem !important;
}

.Touch-modal .MuiChip-root {
    background-color: #29b6f6 !important;
    color: white;
    margin-bottom: 0.3rem;
}

.Touch-modal .MuiChip-root svg {
    color: white;
}

/* Text Area */
.Touch-body textarea {
    resize: none;
    background-color: #f0f5fa;
    padding: 1rem;
    border-radius: 0.75rem;
    width: 100%;
    outline: none;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #5b5b5b;
    min-height: 277px;
}
.max-length-email-content {
    color: #ababab;
    font-size: 0.9rem;
    color: #5b5b5b;
    font-size: 0.9rem;
    text-align: justify;
}

/* Share Link */
.Touch-body .share-this-link {
    color: #5b5b5b;
    font-size: 1rem;
}

.Touch-body .bottom-share {
    align-items: center;
    margin-top: 2rem;
}

.Touch-body .bottom-share button {
    font-weight: bold;
    color: #03a9f4;
    font-size: 18px;
}
.Radio-label {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #039be5;
}
.justify-space-between {
    justify-content: space-between;
    margin-top: 35px;
}
.button-not-radio {
    position: relative;
    left: 481px;
}
.send-button {
    background: #039be5;
    border-radius: 8px;
    width: 130px;
    height: 33px;
    color: #ffffff;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
}
.sent {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    color: #444444;
}

/* Buttons */
.Touch-modal .close-button button {
    background: #cfd8dc !important;
    border-radius: 0.6rem !important;
    color: #5a5a5a !important;
    min-width: 8rem !important;
    text-transform: capitalize !important;
}
.Touch-modal .save-button button {
    background: #067eed !important;
    border-radius: 0.6rem !important;
    color: white !important;
    font-weight: bold !important;
    min-width: 8rem !important;
    text-transform: capitalize !important;
}

@media (max-width: 767px) {
    .request-other-to-Touch {
        margin-top: 1rem;
    }
    .request-other-to-Touch button {
        display: flex;
        justify-content: center;
    }
    .Touch-modal .Touch-body {
        width: 60vw;
    }
    .service-card .services-left {
        border-right: 0px;
    }
}

@media (max-width: 575px) {
    .Touch-modal .Touch-body {
        width: 90vw;
    }
}

@media (max-width: 460px) {
    .Touch-modal .Touch-body {
        width: 98vw;
    }
}
.curser-pointer {
    cursor: pointer;
}

@media (min-width: 1200px) {
    .action-items {
        padding: 0px !important;
    }
}

@media (min-width: 1550px) {
    .service-card {
        max-width: 768px;
    }
}

@media (max-width: 992px) {
    .service-card {
        max-width: 500px;
    }
}
@media only screen and (max-width: 500px) and (min-width: 400px) {
    .Radio-label {
        font-size: 7px;
    }
    .send-button {
        width: 80px;
        margin-top: 50px;
    }
    .companyDetail {
        width: 350px;
    }
}
@media only screen and (max-width: 600px) and (min-width: 501px) {
    .Radio-label {
        font-size: 11px;
    }
    .send-button {
        width: 90px;
        margin-top: 50px;
    }
    .companyDetail {
        width: 450px;
    }
}
@media only screen and (max-width: 555px) and (min-width: 502px) {
    .companyDetail {
        width: 460px;
    }
}
@media only screen and (max-width: 667px) and (min-width: 556px) {
    .companyDetail {
        width: 500px;
    }
}

@media (max-width: 1000px) {
    .send-button {
        margin-top: 50px;
    }
}
