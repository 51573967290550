.preference_tag_item{
    margin-left: 10px;
    background-color: #29b6f6;
    color: white;
    font-weight: 700;
    padding: 5px 10px;
    font-size: 14px;
    border-radius: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.profile-tags-add_preference{
    background-color: #546e7a;
    display: inline;
    border-radius: 1rem;
    padding: 0.2rem 0.8rem;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    color: white;
    position: relative;
    display: inline-block;
    cursor: pointer;
    font-size: 15px;
}