.navbar {
    position: fixed;
    height: 5rem;
    top: 0;
    left: 0;
    width: 100%;
    background-color: white;
    padding: 1rem 2rem;
}
.launch-button {
    display: flex !important;
}
.launch-buttons-login {
    border: 1px solid #067eed;
    border-radius: 10px;
    background-color: white;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    padding: 6px 26px;
    color: #067eed;
    min-width: 120px;
    margin-right: 25px;
}
.launch-buttons-signup {
    background: #067eed;
    border-radius: 10px;
    padding: 6px 26px;
    min-width: 150px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    color: #ffffff !important;
}

.navbar .logo {
    letter-spacing: 0.1em;
    font-weight: 600;
    font-size: 2rem;
    cursor: pointer;
}

.navbar .logo span:first-child {
    color: #03a9f4;
}

.navbar .logo span:last-child {
    color: #017eb8;
}

.navbar-hamburger {
    display: none;
}

.offcanvas.show,
.offcanvas-toggling {
    margin-top: 5rem;
    width: 100%;
}

.offcanvas-backdrop {
    opacity: 0 !important;
}

.offcanvas {
    background-color: rgba(96, 125, 139, 0.9) !important;
}

.menu-box {
    border-bottom: 0.1rem solid white;
}

.menu-box a {
    color: white;
    line-height: 3.6rem;
    font-size: 1.25rem;
}
.join-button button {
    /* text-transform: capitalize !important; */
    font-size: 15px;
}

@media (max-width: 768px) {
    .navbar-buttons {
        display: none !important;
    }
    .navbar-hamburger {
        display: block;
    }
    .navbar .logo {
        font-size: 1.5rem;
    }
}
