.home-wrapper {
    margin-top: 120px;
}

.redeemLinks,
.earnLinks {
    position: relative;
}

.redeemLinks::after,
.earnLinks::after {
    content: "";
    position: absolute;
    display: block;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: #252525;
}

.earnLinks:hover,
.redeemLinks:hover {
    color: #0c8aff;
}

.earnLinks:hover::after,
.redeemLinks:hover::after {
    background: #0c8aff;
}

.sliderCompLink:hover {
    text-decoration: underline;
}

.adContainer {
    max-height: 246px;
    overflow: hidden;
}

.adBox {
    display: grid;
    grid-template-columns: 1fr;
}
.home-section .upcoming-events .header {
    min-height: 280px;
}

@media (max-width: 1200px) {
    .adBox {
        grid-template-columns: 2fr 1fr;
        gap: 1rem;
    }
}

@media (max-width: 768px) {
    .home-wrapper {
        margin-top: 20px !important;
    }
    .businessLeadeshipBox {
        flex-wrap: wrap;
    }

    .leadeshipLine {
        display: none;
    }
    .adBox {
        display: grid;
        grid-template-columns: 1fr;
    }
}
