/* Profile Business Leaders */
.profile-business-leads {
    border: 1px solid #6e6d6d;
    border-radius: 0.6rem;
    margin-top: 2rem;
    overflow: hidden;
}
.profile-business-header {
    display: flex;
    justify-content: center;
    color: #3b3b3b;
    font-size: 1.15rem;
    font-weight: 600;
    padding: 1rem;
    border-bottom: 1px solid #6e6d6d;
}
/* .profile-business-persons {
    margin-bottom: 3rem;
} */
.profile-business-person {
    display: flex;
    align-items: center;
    min-height: 3.2rem;
    padding: 0.75rem 1rem;
    border-bottom: 1px solid #6e6d6d;
}
.profile-business-person .d-flex figure {
    border-radius: 50%;
    overflow: hidden;
    margin-right: 2rem;
}
.profile-business-person .d-flex figure img {
    width: 72px;
    height: 72px;
}
.profile-business-person-details {
    display: flex;
    align-items: center;
}
.profile-business-person-details div h3 {
    color: #555555;
    font-weight: bold;
    line-height: 2rem;
}
.profile-business-person-details div h5 {
    color: #555555;
    line-height: 1.5rem;
}
.select-leader label {
    font-weight: bold;
    padding: 0.5rem 0;
}
.select-leader input {
    background: #e6e6e6;
    width: 470px;
    outline: none;
    border: none;
    border: 1px solid rgb(172, 172, 172);
    border-radius: 0.75rem;
    padding: 0.5rem 1rem;
}

.leader-image-upload {
    display: flex;
    height: 5.5rem;
}
.leader-image {
    display: flex;
    padding-right: 1rem;
    padding-bottom: 2.5rem;
}
.leader-image .file-box {
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 0.3rem;
    padding: 0.8rem 0.6rem;
    cursor: pointer;
}

.leader-image .browse-files {
    display: flex;
    justify-content: end;
    padding-right: 4px;
    margin-top: 2rem;
}

.leader-image .browse-files div {
    color: #067eed;
    font-weight: 600;
    padding: 0.5rem 0.8rem;
    margin-top: 1rem;
    border: 0.1rem solid #8a8a8a;
    border-radius: 0.5rem;
    cursor: pointer;
}

.leader-image div label {
    color: #5a5a5a;
    font-weight: bold;
}

.leader-image center {
    height: 4rem;
}

.leader-image center .blank-image {
    height: 5rem;
    width: 5rem;
    background-color: #818181;
    border-radius: 50%;
}

.leader-image img {
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
}

.leader-image input {
    display: none;
}

.leader-image span {
    font-size: 0.75rem;
    color: #474747;
}

.delete-leader {
    cursor: pointer;
}

.leader-modal-cont {
    height: auto;
    max-height: 250px;
    overflow-y: scroll;
}

@media (max-width: 400px) {
    .select-leader input {
        width: 325px;
    }
}

@media (max-width: 500px) {
    .leader-image .browse-files div {
        font-size: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
}

@media (max-width: 787px) {
    .leader-image {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}
