.Blog-card {
    width: 100%;
    height: auto;
    border-radius: 0.75rem;
    box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.15);
    padding: 1.25rem 1.25rem 0 1.25rem;
    margin-right: 3rem;
    margin-bottom: 2rem;
    cursor: pointer;
}

.pagination-buttons button {
    margin: 0 0.5rem;
    color: #a4a4a4;
}

.pagination-buttons .active-button {
    padding: 0.5rem;
    font-size: 1.15rem;
    color: #01579b;
    border-radius: 0.5rem;
    background-color: #eceff1;
}
.Img {
    width: 100%;
    border-radius: 10px;
    height: 278px;
}

.service-tags-blog {
    margin-top: 5px;
    padding-top: 1.2rem;
    max-height: 6.2rem;
    overflow: hidden;
}

.service-tags-blog div {
    margin-bottom: 1.25rem;
    display: inline-block;
    margin-right: 0.75rem;
}

.service-tags-blog span {
    background-color: #03a9f4;
    border-radius: 1rem;
    padding: 0.4rem 1.25rem;
    color: white;
    text-align: center;
    font-size: 0.9rem;
}

/* .service-tags {
    margin-top: 25px;
}



.service-tags span {
    background-color: #03a9f4;
    border-radius: 1rem;
    padding: 0.4rem 1.25rem;
    color: white;
    text-align: center;
    font-size: 0.9rem;
    margin-right: 20px;
} */

.pagination-pre {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
}
.otherImg {
    width: 167px;
    height: 116px;
    border-radius: 10px;
}
@media (max-width: 770px) {
    .otherImg {
        width: 100%;
        height: 167px;
        border-radius: 10px;
    }
    .pagination-buttons button {
        display: none;
    }
}
