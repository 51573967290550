/* Scroll Bar */
::-webkit-scrollbar {
    width: 0;
}

.signup-container {
    width: 100%;
    display: flex;
}

.signup-container .signup-left {
    height: 100vh;
    width: 45%;
}

.signup-container .signup-left img {
    height: 100vh;
    width: auto;
    object-fit: cover;
    position: fixed;
}

.signup-container .signup-right {
    max-height: 100vh;
    height: 100vh;
    width: 55%;
    scroll-behavior: auto;
    margin-top: 3rem;
}

.signup-container .signup-right img {
    cursor: pointer;
    width: 4rem;
}

.logo-main {
    display: flex;
    margin-top: 0rem;
    justify-content: center;
}

.signup-container .signup-right h1 {
    font-size: 3rem;
    text-align: center;
    margin-top: 2rem;
    font-weight: 600;
}

.signup-container .signup-right .signup-right-form {
    margin-top: 2rem;
    padding: 0 7rem;
}

.signup-container .signup-right .signup-right-form .form-group {
    width: 100%;
    display: block;
    margin: 1rem 0 0 0;
    position: relative;
}

.signup-container .signup-right .signup-right-form .signup-right-form-name {
    display: flex;
}

.signup-container .signup-right .signup-right-form .signup-right-form-name div {
    width: 50%;
}

.signup-container
    .signup-right
    .signup-right-form
    .signup-right-form-name
    div:first-child {
    margin-right: 0.75rem;
}

.signup-container
    .signup-right
    .signup-right-form
    .signup-right-form-name
    div:last-child {
    margin-left: 0.75rem;
}

.signup-container
    .signup-right
    .signup-right-form
    .form-group
    .password-input
    svg {
    position: absolute;
    right: 1rem;
    top: 2.8rem;
    cursor: pointer;
}
@media (max-width: 400px) {
    .signup-container
        .signup-right
        .signup-right-form
        .form-group
        .password-input
        svg {
        position: absolute;
        right: -1rem;
        top: 2.8rem;
        cursor: pointer;
    }
}
@media (max-width: 990px) and (min-width: 768px) {
    .signup-container
        .signup-right
        .signup-right-form
        .form-group
        .password-input
        svg {
        position: absolute;
        right: 2.5rem;
        top: 2.8rem;
        cursor: pointer;
    }
}
@media (max-width: 768px) and (min-width: 400px) {
    .signup-container
        .signup-right
        .signup-right-form
        .form-group
        .password-input
        svg {
        position: absolute;
        right: 0.5rem;
        top: 2.8rem;
        cursor: pointer;
    }
}

.signup-container .signup-right .signup-right-form .form-group .shared-email {
    font-size: 0.75rem;
    color: #707070;
}

.signup-container .signup-right .signup-right-form .form-group label {
    display: block;
    font-weight: 600;
    color: #707070;
}

.signup-container .signup-right .signup-right-form .form-group .error-input {
    border: 0.1rem solid rgb(204, 1, 1);
}

.signup-container .signup-right .signup-right-form .form-group input {
    display: block;
    width: 100%;
    padding: 0.5rem 0.8rem;
    outline: none;
    border: 0.1rem solid #8d8d8d;
    border-radius: 0.2rem;
    margin: 0.5rem 0;
}

.signup-container .signup-right .signup-right-form .form-group input:focus {
    border: 0.1rem solid #067eed;
}

.signup-container
    .signup-right
    .signup-right-form
    .form-group
    .password-validation {
    /* position: absolute; */
    width: 100%;
    top: 2rem;
    left: 0;
    background-color: #f4f4f4;
    border: 0.25rem solid #f4f4f4;
    z-index: 10;
    padding: 1rem;
    border-radius: 0.25rem;
}

.signup-container
    .signup-right
    .signup-right-form
    .form-group
    .password-validation
    p {
    line-height: 1.25rem;
    display: flex;
    align-items: center;
    font-size: 0.8rem;
    line-height: 1.25rem;
}

.signup-container .signup-right .signup-right-form .forgot-password {
    color: #067eed;
    font-size: 0.875rem;
    font-weight: 600;
    cursor: pointer;
}

.signup-container .signup-right .signup-right-form .submit-button {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 2rem;
}

.signup-container .signup-right .signup-right-form .submit-button button {
    border: none;
    outline: none;
    padding: 0.5rem 2rem;
    font-size: 1rem;
    background: #039be5;
    box-shadow: none;
    border-radius: 8px;
    color: white;
    font-weight: bold;
    cursor: pointer;
}

.signup-container .signup-right .signup-right-form .submit-button button:hover {
    background: #0277bd;
}

.signup-container
    .signup-right
    .signup-right-form
    .submit-button
    button:disabled {
    background: #676767;
}

.signup-container
    .signup-right
    .signup-right-form
    .submit-button
    button:disabled:hover {
    background: #515151;
    cursor: not-allowed;
}

.signup-container .signup-right .signup-right-form .already-have-account {
    text-align: center;
    font-weight: 550;
    margin: 1rem 0;
    padding-bottom: 2rem;
}

.signup-container .signup-right .signup-right-form .already-have-account span {
    color: #067eed;
    cursor: pointer;
}

.signup-container
    .signup-right
    .signup-right-form
    .already-have-account
    span:hover {
    text-decoration: underline;
    font-weight: 600;
}

.email-sent {
    font-size: 1.25rem;
    font-weight: bold;
    text-align: center;
    line-height: 2.4rem;
}

@media (max-width: 992px) {
    .signup-container .signup-right .signup-right-form {
        padding: 0 3rem;
    }
    .signup-container .signup-left {
        display: none;
    }
    .signup-container .signup-right {
        width: 100%;
    }
}

@media (max-width: 768px) {
    .signup-container .signup-right .signup-right-form {
        padding: 0 2rem;
    }
    .signup-container .signup-right h1 {
        font-size: 2rem;
    }
    .signup-container .signup-right .signup-right-form .signup-right-form-name {
        width: 100%;
        display: block;
    }
    .signup-container
        .signup-right
        .signup-right-form
        .signup-right-form-name
        div {
        width: 100%;
    }
    .signup-container
        .signup-right
        .signup-right-form
        .signup-right-form-name
        div:first-child {
        margin-right: 0rem;
    }

    .signup-container
        .signup-right
        .signup-right-form
        .signup-right-form-name
        div:last-child {
        margin-left: 0rem;
    }
    .signup-container
        .signup-right
        .signup-right-form
        .form-group
        .password-validation {
        /* position: absolute; */
        width: 93%;
    }
    .signup-AiOutlineEyeInvisible {
        margin-right: 50px;
    }
}

@media (max-width: 576px) {
    .signup-container .signup-right .signup-right-form {
        padding: 0 1rem;
    }
    .signup-container .signup-right .signup-right-form .submit-button button {
        width: 100%;
    }
}

@media (max-width: 348px) {
    .rest-body .signup-right-heading h1 {
        font-size: 24px;
    }
    .signup-right-form {
        width: 100vw;
        margin-left: 8px;
    }
    .signup-container .signup-right .signup-right-form .submit-button button {
        width: 200px;
    }
}
@media only screen and (max-width: 600px) and (min-width: 400px) {
    .signup-container .signup-right .signup-right-form .submit-button button {
        width: 200px;
    }
    .signup-container .signup-right .signup-right-form {
        margin-left: 15px;
    }
}
@media (max-width: 1024px) {
    .email-sent {
        line-height: normal;
    }
}
