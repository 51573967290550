.head-panel {
    padding: 2rem 0 1rem 6rem;
    color: #37474f;
}
.launch-form {
    margin-top: 90px;
}
.main-panel {
    display: flex;
    margin: 0rem auto;
    width: 80%;
    height: 100vh;
}

.main-panel-2 {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    margin: 0rem auto;
    width: 90%;
    height: 100vh;
}

.main-panel-2-button {
    margin: 0rem auto;
    width: 90%;
}

.preview-panel {
    width: 70%;
    border: 1px solid grey;
    min-height: 50vh;
    border-radius: 1rem;
}
.preview-panel img {
    height: 100vh;
    width: 100%;
    object-fit: cover;
    border-radius: 1rem;
}

.form-panel {
    width: 35%;
    margin-left: 1rem;
}
.add-content {
    border: 1px solid grey;
    border-radius: 0.5rem;
}
.add-content-media {
    display: flex;
    text-align: center;
    padding: 0.3rem;
}
.add-content-image {
    flex: 1;
}
.add-content-image:hover {
    background: #dcedf6;
    cursor: pointer;
}
.add-content-gif {
    flex: 1;
}
.add-content-gif:hover {
    background: #dcedf6;
    cursor: pointer;
}
.add-content-heading {
    background: #f2f2f2;
    color: #44545c;
    font-size: 20px;
    font-weight: bold;
    line-height: 3rem;
    border-top: 1px solid grey;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.3rem;
    padding: 0.2rem 0 0.2rem 0.5rem;
}
.additonal-details {
    border: 1px solid grey;
    border-radius: 0.5rem;
    margin-top: 1rem;
    height: 80vh;
}
.additonal-details-heading {
    background: #f2f2f2;
    color: #44545c;
    line-height: 3rem;
    border-top: 1px solid grey;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.3rem;
    font-size: 20px;
    font-weight: bold;
    padding: 0.2rem 0 0.2rem 0.5rem;
}
.launch-campaign {
    background: #03a9f4;
    border: 0px;
    border-radius: 4px;
    height: 3rem;
    width: 12rem;
    margin-top: 4rem;
    color: white;
    font-weight: bold;
    padding: 0.5rem;
    margin-bottom: 1rem;
}
.ad-blank-image {
    text-align: center;
    margin-top: 20rem;
    color: grey;
}
.closed-campaign img {
    padding: 1rem;
}
.closed-campaign b {
    font-size: 20px;
}
.closed-campaign-text {
    padding: 1rem 0 1rem 0;
    color: #848484;
}
.additonal-details-budget span {
    color: #797979;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    padding: 2rem 0 0 0.5rem;
}
.budget {
    padding: 0rem 1rem 1rem 1rem;
}
.additonal-details-budget-head {
    padding: 1rem 0 0 0.4rem;
}
.additonal-details-campaign-head {
    padding: 1rem 0 0 0.4rem;
}
.budget input {
    background-color: #e1f5fe;
    width: 100%;
    height: 38px;
    left: 971px;
    top: 505px;
    margin-top: 1rem;
    border-radius: 3px;
    padding-left: 0.5rem;
    outline: none;
}
.error {
    border: 1px solid #c11717;
}

.error-text {
    color: #c11717;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    margin-top: 2px;
}
.budget ::placeholder {
    padding-left: 0.8rem;
}
.additonal-details-campaign-type span {
    color: #797979;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    padding-left: 0.5rem;
}
.additonal-details-target-market span {
    color: #797979;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    padding-left: 0.5rem;
}
.panel-radio-group-1-text span {
    color: #707070;
    font-size: 14px;
}
.panel-radio-group {
    padding: 1rem;
}
.panel-radio-input {
    border: 0px;
    width: 10%;
    height: 1rem;
}
.panel-radio-group label {
    font-size: 16px;
    color: #444444;
}
.target-group input {
    background-color: #ffffff;
    width: 100%;
    height: 38px;
    left: 971px;
    top: 505px;
    margin-top: 1rem;
    border: 1px solid #8d8d8d;
    border-radius: 3px;
    padding-left: 0.5rem;
}
.target-group ::placeholder {
    padding-left: 0.8rem;
    color: rgb(105, 95, 95);
}
.target-group {
    padding: 0rem 1rem 1rem 1rem;
}
.markets {
    width: 100%;
    display: inline-block;
}

.markets div {
    display: inline-flex;
    position: relative;
    padding: 0.3rem 0.5rem;
    margin: 0.2rem 0.2rem;
    border-radius: 1rem;
    background: #29b6f6;
}

.markets div p {
    min-width: 3rem;
    color: #ffffff;
    font-weight: 300;
    /* z-index: 0.1; */
    /* z-index: -1; */
    padding-right: 0.5rem;
}
.suggestion-search {
    top: 5.35rem;
    left: 0;
    max-height: 100px;
    overflow-y: auto;
    padding: 1rem 0 1rem 1rem;
    width: 100%;
    background-color: white;
    border: 1px solid rgb(180, 176, 176);
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
}
.close-images {
    display: flex;
}
/* @media (max-width: 1100px) {
        .launch-form{
            flex-flow: column wrap;
        }
        .main-panel{
            flex-flow: row wrap;
            margin: 0rem 4rem 0 3rem;
        }
        .additonal-details{
            height: 75vh;
        }
        .launch-campaign{
            margin-top: 25rem;
            justify-content: center;
            align-items: center;
            margin-left: 13rem;
            display: flex;
        }
        .add-content{
            margin-top: 1rem;
        }
    } */
/* @media (max-width: 1000px) {
        .launch-form{
            flex-flow: column wrap;
        }
        .main-panel{
            flex-flow: row wrap;
            margin: 0rem 4rem 0 3rem;
        }
        .additonal-details{
            height: 75vh;
        }
        .launch-campaign{
            margin-top: 25rem;
            justify-content: center;
            align-items: center;
            margin-left: 13rem;
            display: flex;
        }
        .add-content{
            margin-top: 1rem;
        }
    } */
@media (max-width: 768px) {
    .preview-panel {
        width: 60%;
    }
}
@media (max-width: 768px) {
    .launch-form {
        margin-top: 0px;
    }
    .main-panel-2 {
        width: 90%;
        flex-direction: column;
    }

    .main-panel-2-button {
        width: 90%;
    }
}
@media (max-width: 900px) {
    .launch-form {
        flex-flow: column wrap;
    }
    .main-panel {
        flex-direction: column;
        margin: 0rem auto;
    }
    .main-panel-2 {
        flex-direction: column;
    }
    .main-panel-2-button {
        margin-top: 450px;
    }
    .additonal-details {
        height: 75vh;
    }
    .preview-panel {
        width: 100%;
    }
    .form-panel {
        width: 100%;
        margin-left: 0.1rem;
    }
    .launch-campaign {
        margin-top: 2rem;
        justify-content: center;
        align-items: center;

        display: flex;
        width: 30rem;
    }
    .add-content {
        margin-top: 1rem;
    }
}
@media (max-width: 800px) {
    .launch-form {
        flex-flow: column wrap;
    }
    .main-panel {
        margin: 0rem auto;
    }
    .additonal-details {
        height: 85vh;
    }
    .launch-campaign {
        margin-top: 35rem;
        justify-content: center;
        align-items: center;

        display: flex;
    }
    .add-content {
        margin-top: 1rem;
    }
}
@media (max-width: 600px) {
    .launch-form {
        flex-flow: column wrap;
    }
    .additonal-details {
        height: 85vh;
    }
    .launch-campaign {
        margin-top: 35rem;
        justify-content: center;
        align-items: center;
        max-width: 100%;

        display: flex;
    }
    .add-content {
        margin-top: 1rem;
    }
}
@media (max-width: 500px) {
    .launch-form {
        flex-flow: column wrap;
    }
    .additonal-details {
        height: 85vh;
    }
    .launch-campaign {
        max-width: 100%;
        margin-top: 35rem;
        justify-content: center;
        align-items: center;

        display: flex;
    }
    .add-content {
        margin-top: 1rem;
    }
}

@media (max-width: 400px) {
    .launch-form {
        flex-flow: column wrap;
    }

    .form-panel {
        margin-left: 0.1rem;
    }
    .additonal-details {
        height: 80vh;
    }
    .launch-campaign {
        width: 100%;
        margin-top: 25rem;
        justify-content: center;
        align-items: center;
        display: flex;
    }
    .add-content {
        margin-top: 1rem;
    }
    .preview-panel img {
        height: 40vh;
        width: 100%;
        object-fit: cover;
        border-radius: 1rem;
    }
    .close-images {
        display: flex;
        flex-flow: row wrap;
        width: 22rem;
    }
    .close-images img {
        width: 49%;
    }
    .closed-campaign {
        padding: 1rem;
    }
    /* .head-panel {
        padding: 2rem 0 1rem 2rem;
    } */
}
/* @media (max-width: 370px) {
        .launch-form{
            flex-flow: column wrap;
        }
        .main-panel{
            flex-flow: row wrap;
            margin: 0rem 4rem 0 1rem;
        }
        .additonal-details{
            height: 74vh;
        }
        .launch-campaign{
            margin-top: 24rem;
            justify-content: center;
            align-items: center;
            margin-left: 10rem;
            display: flex;
        }
        .add-content{
            margin-top: 1rem;
        }
        .close-images{
            display: flex;
            flex-flow: row wrap;
            width: 22rem;
        }
        .close-images img{
            width: 49%;
        }
        .closed-campaign{
            padding: 1rem;
            
        }
        
    } */

/* .launch-campaign .MuiButtonBase-root {
    background-color: #29b6f6 !important;
    color: white !important;
} */

.target .form-group input {
    border: 0px !important;
}
.target {
    padding: 0px 8px;
    margin-top: 4px;
}

.MuiSvgIcon-root {
    /* color: black !important; */
}
/* .launch-campaign .MuiCheckbox-colorSecondary .Mui-checked {
    color: #29b6f6 !important;
} */
/* .MuiSelect-root {
    background-color: white !important;
} */

.divleft {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 50%;
}
@media (max-width: 900px) {
    .ad-blank-image {
        margin-top: 150px;
    }
    .heading-ad {
        margin-left: -60px !important;
    }
    .ad-image {
        width: 50vw !important;
        height: auto !important;
    }
}

@media only screen and (max-width: 700px) and (min-width: 400px) {
    .ad-image {
        width: 50vw !important;
        height: auto !important;
    }
}
@media only screen and (max-width: 900px) and (min-width: 701px) {
    .ad-image {
        width: 40vw !important;
        height: auto !important;
    }
}
@media only screen and (max-width: 768px) and (min-width: 767px) {
    .main-panel-2 {
        margin-top: 100px;
    }
}
@media (max-width: 500px) {
    .launch-form {
        flex-flow: column wrap;
    }
}
