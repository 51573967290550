* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Poppins", "Roboto", "Segoe UI", "Oxygen", "Ubuntu",
        "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
    width: 2px;
    height: 1px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgb(189, 186, 186);
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #039be5;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #039be5;
}

.pagination-buttons {
    display: flex;
    align-self: center;
}

@media (max-width: 700px) {
    .pagination-buttons {
        display: none !important;
    }
}
