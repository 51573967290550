.button button {
    box-shadow: none;
    color: #067eed;
}

.button button:hover {
    box-shadow: none;
    color: #045db1;
}

.logo {
    letter-spacing: 0.1em;
    font-weight: 600;
    font-size: 2rem;
    cursor: pointer;
}

.logo span:first-child {
    color: #03a9f4;
}

.logo span:last-child {
    color: #017eb8;
}
