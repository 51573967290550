.privacy-header {
    text-align: center;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #000000;
    margin-bottom: 10px;
    padding-top: 15px;
    padding-top: 25px;
    margin-top: 90px;
}
.privacy-subheader {
    font-weight: 700;
}

.paragraph {
    font-size: 16px;
}
