.upcoming-events {
    margin-top: 90px;
}

.upcoming-events .topnav {
    position: absolute;
    right: 5%;
    top: 0;
}

.fav-heading-search {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.card {
    border: none !important;
}
.upcoming-events .topnav .form-control {
    min-width: 300px;
    border: none !important;
    margin-right: 1.5rem;
}

.upcoming-events .topnav .form-control input:focus,
.upcoming-events .topnav .form-control:focus {
    outline: none;
    border: none !important;
}
.border-end {
    border-color: rgba(159, 159, 159, 1) !important;
}
.upcoming-events .header {
    height: auto;
    width: 100%;
    background-color: #eaf0f2;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #eaf0f2 url("../../assets/events.png") no-repeat;
    padding-bottom: 16px;
    background-size: cover;
}

.upcoming-events .header .add-btn {
    background-color: #03a9f4;
    border-radius: 5px;
    height: 3rem;
    color: white;
    min-width: 20rem;
    margin-top: 14rem;
    padding: 0.5rem;
    font-weight: bold;
    font-size: 20px;
}

.upcoming-events .event-list h1 {
    margin-top: 35px;
    margin-bottom: 20px;
    font-size: 1.8rem;
    font-weight: bold;
}

@media (max-width: 768px) {
    .upcoming-events {
        margin-top: 0px;
    }

    .upcoming-events .topnav {
        position: absolute;
        right: 15%;
        top: 0;
    }
}

@media (max-width: 670px) {
    .upcoming-events .topnav {
        right: 10%;
    }

    /* .upcoming-events .topnav .search-icon,
    .upcoming-events .topnav .liked-event p {
        display: none;
    } */
}
@media (max-width: 670px) {
    .form-group {
        background-color: white;
    }
    .upcoming-events .topnav .form-control {
        min-width: 250px;
        border: none !important;
        margin-right: 0.5px !important;
    }
    .liked-event {
        font-size: 12px;
    }
}

@media (min-width: 760px) {
    .main-div {
        margin-left: 3.5rem !important;
        margin-right: 3.5rem !important;
    }
}

@media (max-width: 760px) {
    .main-div {
        margin-left: 2.5rem !important;
        margin-right: 2.5rem !important;
    }
    .fav-heading-search {
        display: flex;
        align-items: stretch;
        flex-direction: column;
    }
}
@media (max-width: 500px) {
    .main-div {
        margin-left: 1.5rem !important;
        margin-right: 1.5rem !important;
    }
}

@media (max-width: 1200) {
    .main-div {
        margin-left: 0rem !important;
        margin-right: 0rem !important;
    }
}

.liked-event {
    margin-left: 25%;
    width: 100% !important;
}
@media only screen and (max-width: 500px) and (min-width: 400px) {
    .search-icon {
        margin-left: -20px;
    }
}
@media only screen and (max-width: 768px) and (min-width: 767px) {
    .topnav {
        margin-top: 90px;
    }
}

.event_popup_banner_section {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: white;
    margin-top: 8px;
}
