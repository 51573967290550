.faqs-inner {
    width: 60%;
    margin: 10rem auto;
}

.faqs-heading {
    font-size: 1rem;
    font-weight: 700 !important;
    color: #3f3f3f;
}

.faqs-answer {
    font-size: 14px;
    color: black !important;
}

.faqs-inner .faqs-title {
    color: #333d43;
    font-size: 2.5rem;
    font-weight: 700 !important;
    text-align: center;
    margin: 3rem 0;
    font-family: "Poppins" !important;
}

.faqs-inner .faqs-sub-title {
    color: #333d43;
    font-size: 2rem;
    font-weight: 700;
    text-align: center;
    margin: 3rem 0 2rem 0;
}

.faqs-inner .faq-contact-message {
    text-align: center;
}
.faqs-inner .faq-contact-message button {
    color: #03a9f4;
    font-weight: bold;
}

.faqs-inner .faq-contact-message a {
    text-align: center;
    color: #03a9f4;
    font-weight: 600;
}

.faqs-inner .MuiPaper-root {
    background-color: #eceff1 !important;
    margin: 0.75rem 0;
    border-radius: 0.75rem !important;
    border: none !important;
    overflow: hidden !important;
    padding: 0.3rem 0;
    min-height: 54px;
    box-shadow: none !important;
}

.MuiTypography-body1 {
    font-family: "Poppins" !important;
}
.faq-modal-container .opacity-25 {
    background: #607d8b !important;
    opacity: 1 !important;
}

.faq-modal-container .border-0 {
    width: 800px;
    height: 540px;
    display: flex;
    justify-content: center;
}
.faq-modal-main-body {
    display: flex;
}
.faq-contact-heading {
    padding: 0 2rem;
    color: #253944;
    font-size: 3rem;
    font-weight: 500;
    font-family: Roboto;
}
.contact-part-hide-mobile {
    align-items: center;
    display: flex;
}
.save-button {
    display: flex;
    justify-content: end;
}

.save-button button {
    width: 7rem;
    margin-right: 2rem;
    background: #067eed;
}
.faq-contact-part {
    width: 480px;
    margin-left: 2rem;
    padding: 0 2rem;
}

@media (max-width: 786px) {
    .faqs-inner {
        width: 85%;
    }
}

/* Media Query */
@media (max-width: 400px) {
    .faqs-inner {
        width: 85%;
    }
    .contact-part-hide-mobile {
        display: none;
    }
    .faq-modal-container .border-0 {
        width: 360px;
    }
    .faq-contact-part {
        margin: 0;
        padding: 0.5rem;
        width: 100%;
    }
    .save-button button {
        width: 7rem;
    }
    .faq-contact-heading {
        padding: 0.5rem 0.5rem 0 0.5rem;
    }
}
@media (max-width: 452px) {
    .faqs-inner .faqs-title {
        font-size: 22px;
    }
    .MuiTypography-body1 {
        font-size: 13px !important;
    }
}
@media only screen and (max-width: 506px) and (min-width: 453px) {
    .faqs-inner .faqs-title {
        font-size: 27px;
    }
}
@media only screen and (max-width: 678px) and (min-width: 506px) {
    .faqs-inner .faqs-title {
        font-size: 30px;
    }
}
@media only screen and (max-width: 996px) and (min-width: 786px) {
    .faqs-inner .faqs-title {
        font-size: 28px;
    }
}
@media only screen and (max-width: 785px) and (min-width: 767px) {
    .faqs-inner .faqs-title {
        font-size: 36px;
    }
}

@media only screen and (max-width: 500px) and (min-width: 400px) {
    .contact-part-hide-mobile {
        display: none !important;
    }
    .faq-contact-heading {
        margin-top: 15px;
        font-size: 30px !important;
    }

    .faq-contact-part {
        margin-left: -30px !important;
    }
    .faq-modal-container .border-0 {
        width: 400px !important;
        height: 540px !important;
        /* display: flex;
        justify-content: center; */
    }
    .faq-modal-main-body {
        margin-left: 28px;
    }
}
@media only screen and (max-width: 600px) and (min-width: 501px) {
    .contact-part-hide-mobile {
        display: none !important;
    }

    .faq-contact-part {
        margin-left: -40px !important;
    }
    .faq-modal-container .border-0 {
        width: 500px !important;
        height: 540px !important;
        display: flex;
        justify-content: center;
    }
    .faq-modal-main-body {
        margin-left: 33px;
    }
    .faq-contact-heading {
        margin-top: 15px;
        font-size: 36px !important;
    }
}
@media only screen and (max-width: 700px) and (min-width: 601px) {
    .contact-part-hide-mobile {
        display: none !important;
    }

    .faq-contact-part {
        margin-left: -40px !important;
    }
    .faq-modal-container .border-0 {
        width: 600px !important;
        height: 540px !important;
        display: flex;
        justify-content: center;
    }
    .faq-modal-main-body {
        margin-left: 80px;
    }
    .faq-contact-heading {
        margin-top: 15px;
        font-size: 38px !important;
    }
}
@media only screen and (max-width: 800px) and (min-width: 701px) {
    .contact-part-hide-mobile {
        display: none !important;
    }

    .faq-contact-part {
        margin-left: -40px !important;
    }
    .faq-modal-container .border-0 {
        width: 700px !important;
        height: 540px !important;
        display: flex;
        justify-content: center;
    }
    .faq-modal-main-body {
        margin-left: 120px;
    }
    .faq-contact-heading {
        margin-top: 15px;
        font-size: 38px !important;
    }
    .save-button {
        margin-right: 90px;
    }
}
@media only screen and (max-width: 1024px) and (min-width: 804px) {
    .faq-contact-heading {
        margin-top: 12px;
    }
}

@media (max-width: 767px) {
    .faqs-inner {
        margin-top: -12px;
    }
}
