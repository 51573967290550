/* Tags Section */
.profile-tags-section {
    padding: 1rem;
}
.profile-tags-section h1 {
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
    color: #5a5a5a;
    font-weight: 500;
}
.profile-tags {
    border: 1px solid rgb(171, 171, 171);
    border-radius: 0.75rem;
    display: inline-block;
    width: 100%;
    min-height: 10rem;
    padding: 1rem;
    background-color: #eceff1 !important;
}

.profile-tags .profile-tags-each {
    background-color: #29b6f6;
    display: inline;
    border-radius: 1rem;
    padding: 0.3rem 0.8rem;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    color: white;
    padding-right: 2rem;
    position: relative;
    display: inline-block;
    word-break: break-word;
}

.profile-tags .profile-tags-each .tags-close-icon {
    color: white;
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    cursor: pointer;
}

.profile-tags .profile-tags-add {
    background-color: #546e7a;
    display: inline;
    border-radius: 1rem;
    padding: 0.2rem 0.8rem;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    color: white;
    padding-right: 2rem;
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.profile-tags .profile-tags-disable {
    background-color: #eeeeee;
    display: inline;
    border-radius: 1rem;
    padding: 0.2rem 0.8rem;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    color: #bdbdbd;
    padding-right: 2rem;
    position: relative;
    display: inline-block;
    cursor: pointer;
}
.profile-modal .WAMuiChipInput-root-1 {
    border: none !important;
    min-width: 300px;
}
.profile-modal .WAMuiChipInput-root-1 label {
    display: none;
}
.profile-modal .WAMuiChipInput-inputRoot-2.WAMuiChipInput-outlined-5 {
    padding-top: 4px;
}
.profile-modal .MuiChip-root {
    background-color: #546e7a !important;
    color: white;
}
.profile-modal .MuiChip-root svg {
    color: white;
}
@media (max-width: 991px) {
    .profile-tags-section {
    }
    .profile-tags {
        background-color: white;
        box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.15);
        border: none;
        border: 1px solid rgb(171, 171, 171);
    }
}
@media (max-width: 576px) {
    .profile-tags-section {
        width: 100vw;
        margin-left: -15px;
    }
}
@media only screen and (max-width: 767px) and (min-width: 577px) {
    .profile-tags-section {
        width: 95vw;
        margin-left: -15px;
    }
}
