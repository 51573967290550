.adpayment {
    max-width: 90%;
    margin: 120px auto;
}
.adpayment-head-panel {
    font-size: 18px;
}
.main-card-panel {
    display: flex;
    width: 100%;
    min-height: 50vh;
}
.main-card-panel input:focus {
    border: 1px solid #067eed;
}

.payment-left-panel {
    height: 100%;
    width: 50%;

    padding: 1rem 3rem 0 0;
    border-right: 2px solid grey;
    height: 410px;
}

.payment-right-panel {
    width: 50%;
    margin-left: 2rem;
    padding: 1rem;
    margin-right: 3rem;
}

.mastercard-heading position-relative {
    background: grey;
}
.add-cvv-card-number {
    display: flex;
    padding: 1rem;
    background: #ffffff;

    border-radius: 10px !important;
    border: 1px solid rgb(212, 205, 205);
}
.add-cvv {
    flex: 1;
}
.add-card-number {
    flex: 2;
}

.cvv-card-number {
    color: #8d8d8d;
}
.add-cvv-input {
    display: block;
    width: 100%;
    padding: 0.5rem 0.8rem;
    outline: none;
    border: 0.1rem solid #8d8d8d;
    border-radius: 0.2rem;
}

.card-details {
    border: 1px solid rgb(212, 205, 205);
    border-radius: 0.5rem;
    margin-left: 4rem;
    margin-top: 2rem;
    padding: 1rem;
    color: #8d8d8d;
}
.card-details label {
    padding: 0.4rem;
}
.date-cvv-detail {
    display: flex;
}
.card-validity {
    width: 70%;
    padding-right: 1rem;
}
.add-card-cvv {
    width: 30%;
}
.add-card-number-input1 input {
    display: block;
    width: 100%;
    padding: 0.5rem 0.8rem;
    outline: none;
    border: 0.1rem solid #8d8d8d;
    border-radius: 0.2rem;
}
.add-card-number-input {
    display: block;
    width: 100%;
    padding: 0.5rem 0.8rem;
    outline: none;
    border: 0.1rem solid #8d8d8d;
    border-radius: 0.2rem;
}
.card-holder-name input {
    display: block;
    width: 100%;
    padding: 0.5rem 0.8rem;
    outline: none;
    border: 0.1rem solid #8d8d8d;
    border-radius: 0.2rem;
}
.card-validity-input input {
    display: block;
    width: 100%;
    padding: 0.5rem 0.8rem;
    outline: none;
    border: 0.1rem solid #8d8d8d;
    border-radius: 0.2rem;
}
.add-cvv-input input {
    display: block;
    width: 100%;
    padding: 0.5rem 0.8rem;
    outline: none;
    border: 0.1rem solid #8d8d8d;
    border-radius: 0.2rem;
}

.add-cards {
    display: flex;
    background: #e1f0fa;
    border: 1px solid rgb(212, 205, 205);
    border-radius: 0.5rem;
    margin-left: 4rem;
    margin-top: 2rem;
    padding: 1rem;
}
.add-card-logo {
    width: 10%;
    text-align: start;
}
.add-card-head {
    width: 40%;
    color: #0e3854;
    font-size: 17px;
}
.add-card-button {
    width: 50%;
    text-align: end;
}
.available-credits {
    display: flex;

    background: #eceff1;
    border: 1px solid #b6b7b8;
    box-shadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.25);
    border-radius: 7px;
    margin-top: 2rem;
    padding: 1rem;
}
.avaiable-credit-text {
    flex: 1;
    text-align: start;
    color: #676767;
}
.avaiable-credit {
    flex: 1;
    text-align: end;
    color: #676767;
}
.payment-right-panel div {
    margin-top: 1rem;
}
.payment-detail-head {
    font-size: 18px;
}
.selected-criteria {
    display: flex;
}
.total-price {
    display: flex;
}
.free-credit {
    display: flex;
    padding-bottom: 1rem;
}
.total-amount {
    display: flex;
}
.selected-criteria-value {
    padding-left: 7rem;
}
.total-price-value {
    padding-left: 11rem;
}
.free-credit-value {
    padding-left: 12rem;
}
.total-amount-value {
    padding-left: 7rem;
}
.ad-payment-button button {
    background: #039be5;
    color: #ffffff;
    font-size: 16px;
    font-weight: 700;
    border-radius: 0.5rem;
    width: 12rem;
    height: 2.5rem;

    align-items: center;
    justify-content: center;
}
.ad-payment-button {
    justify-content: flex-end;
    display: flex;
}

.show-mobile-ad-payment-button {
    display: none;
}

.adPaymentBtn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 2rem;
}

#backBtn {
    background: #909090;
    border-radius: 8px;
    padding: 5px 50px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    /* identical to box height */

    color: #ffffff;
}

@media (max-width: 400px) {
    .main-card-panel {
        display: flex;
        flex-direction: column;
    }
    .payment-left-panel {
        width: 100%;
        margin: 2px;
        border-bottom: 1.5px solid grey;
        padding: 1rem 1rem 0 0.5rem;
    }
    .payment-right-panel {
        width: 100%;
        margin: 2px;
        padding: 1rem 1rem 2rem 1rem;
    }
    .available-credits {
        margin-left: 0;
        margin-bottom: 2rem;
    }
    .add-cards {
        margin-left: 0;
    }
    .cvv-card-number {
        margin-left: 0;
    }
    .card-details {
        margin-left: 0;
    }
    .adpayment-head-panel {
        padding: 2rem 0 1rem 1rem;
    }

    .show-laptop-ad-payment-button {
        display: none;
    }
    hr {
        height: 2px;
        color: black;
    }
}

.cursor-pointer {
    cursor: pointer;
}

.show-mobile-ad-payment-button button {
    background: #039be5;
    color: #ffffff;
    font-weight: 700;
    border-radius: 0.5rem;
    width: 100%;
    height: 3rem;
    font-size: 20px;
    height: 3rem;
    margin: 2rem 3rem 3rem 0;
    align-items: center;
    justify-content: center;
}
.show-mobile-ad-payment-button {
    justify-content: center;
    display: flex;
    margin-left: 2rem;
}

.name-input input[type="text"] {
    height: 38px;
    width: 100%;

    box-sizing: border-box;

    outline: none;

    display: block;

    padding: 0.5rem 0.8rem;
    border: 0.1rem solid #8d8d8d;
    border-radius: 0.2rem;
}
input:focus {
    outline: none !important;
    /* border: 0.5px solid #8d8d8d !important; */
}

@media (max-width: 880px) {
    .main-card-panel {
        flex-direction: column;
    }
    .payment-right-panel {
        margin-top: 4rem;
    }

    .payment-left-panel {
        border-right: none !important;
        margin-left: auto !important;
        margin-right: auto !important;
        padding-right: 0px;
        width: 100% !important;
    }
    .payment-right-panel {
        margin-left: auto !important;
        margin-right: auto !important;
        width: 100% !important;
    }

    .show-laptop-ad-payment-button {
        display: none;
    }

    .show-mobile-ad-payment-button {
        display: flex !important;
        justify-content: center;
        align-items: center;
        margin-left: 0rem !important;
        margin-top: 2rem;
    }
    .show-mobile-ad-payment-button button {
        width: 50% !important;
        margin: 0px !important;
        padding: 5px !important;
    }
}

@media (max-width: 768px) {
    .adpayment-head-panel {
        margin-top: 20px;
    }
    .adpayment {
        font-size: 18px;
        margin-top: 0px;
    }
}

@media (min-width: 881px) {
    .show-laptop-ad-payment-button {
        display: "";
    }
    .show-mobile-ad-payment-button {
        display: none;
    }
}

.promoCode {
    background: #e1f0fa;
    border-radius: 8px;
    text-align: center;
}
@media only screen and (max-width: 768px) and (min-width: 767px) {
    .adpayment {
        margin-top: 130px;
    }
}
@media (max-width: 880px) {
    .payment-right-panel {
        margin-top: -20px;
    }
}
