.payments .heading h1 {
    text-align: center;
    font-size: 2rem;
    color: #0e3854;
    font-weight: 600;
}

.card-icon {
    margin-top: 12px;
}
.card-heading .reg {
    margin-top: 2px !important;
}
.payments .heading h5 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1rem 0;
    color: #007893;
}

/* Payment Secton */
.payments .payments-card {
    height: 450px;
}

.payment-card-box {
    min-height: 20rem;
    border-right: 1px solid black;
}

.text-red {
    color: red;
}

.payment-card-box h2 {
    font-size: 1.5rem;
    font-weight: bold;
}

.payment-details h2 {
    font-size: 1.5rem;
    font-weight: bold;
}

/* Card */
.payment-form {
    width: 100%;
    padding: 0 3rem;
}
.payment-form .card-heading {
    padding: 1.2rem 2rem;
    background-color: #e1f0fa;
    border-radius: 0.4rem;
    margin-bottom: 1rem;
}
.payment-form .card-heading svg {
    position: absolute;
    left: 0.8rem;
    top: 0.8rem;
    color: #0e3854;
}
.payment-form .card-heading h4 {
    padding-left: 2rem;
    font-weight: bold;
    color: #0e3854;
}
.payment-form .inputBox {
    position: relative;
    margin: 0.8rem 0;
}
.payment-form .inputBox svg {
    position: absolute;
    left: 1rem;
    top: 1rem;
    width: 1.4rem;
    height: 1.4rem;
    color: #0e3854;
}
.payment-form .inputBox .input {
    width: 100%;
    border: 0.1rem solid #8d8d8d;
    border-radius: 0.3rem;
    padding: 1rem 3.5rem;
    vertical-align: middle;
    height: 54px;
}
.payment-form .buttonBox {
    display: flex;
    justify-content: center;
    width: 100%;
}

.element-title {
    color: #8d8d8d;
    font-weight: 600;
}

/* Details Heading */
.details-heading h2 {
    font-size: 1.5rem;
    font-weight: 600;
    margin: 1rem 0;
    padding-bottom: 1rem;
}
.details-heading {
    padding: 0 3rem;
}

.details-heading .row .col-8,
.details-heading .row .col-4 {
    color: #555555;
    font-size: 1rem;
    height: 2.8rem;
}

.left-bar {
    border-left: 0.1rem solid black;
}

.total-row {
    font-weight: 600;
    font-size: 1.1rem;
}
.type-free-span {
    color: #1f3849;
    margin: 1rem;
    font-size: 16px;
    margin-bottom: 1rem;
}

.credit-head-panel {
    display: flex;
}

.credit-head-panel img {
    margin-left: 20rem;
}

.promo-code {
    display: flex;
    flex-direction: column;
}
.promo-code label {
    color: #000;
    font-size: 1rem;
    font-weight: 600;
    padding-bottom: 1rem;
    line-height: 28px;
    padding-bottom: 6px;
}
.promo-code input {
    background: #e1f0fa;
    outline: none;
    width: 11rem;
    line-height: 2.5rem;
    border-radius: 7px;
    padding-left: 1rem;
    margin-bottom: 1rem;
}
.continue-button button:disabled {
    background: #676767;
}

.continue-button button:hover {
    background: #0277bd;
}
@media (max-width: 1200px) {
    .credit-head-panel img {
        margin-left: 13rem;
    }
    .payment-form .inputBox .input {
        padding: 1rem 3rem;
    }
}
@media (max-width: 1024px) {
    .left-bar {
        border: none;
    }
    .credit-head-panel img {
        margin-left: 13rem;
    }
    .payment-form .inputBox .input {
        padding: 1rem 3rem;
    }
}

@media (max-width: 767px) {
    .payment-card-box {
        border-right: none;
    }
    .details-heading {
        padding: 0;
    }
    .payment-form {
        padding: 0;
    }
    .credit-head-panel img {
        margin-left: 13rem;
    }
    /* .payment-form .card-heading svg {
        margin-top: 3px;
    } */
}
@media (max-width: 400px) {
    .credit-head-panel img {
        margin-left: 3rem;
    }
    .credit-head-panel img {
        padding: 0;
    }
}
@media only screen and (max-width: 435px) and (min-width: 400px) {
    .cvv-box {
        width: 130px;
        margin-left: -13px;
    }
    .valid-box {
        width: 200px;
    }
}

@media (max-width: 1000px) {
    .payment-form .card-heading svg {
        margin-top: 3px;
    }
}
