.display-flex {
    display: flex;
}
.refer-body .top-heading {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.refer-body .top-heading .icon-wrapper {
    border: 1px solid #455a64;
    padding: 0.5rem;
    font-size: 1.6rem;
    border-radius: 50%;
}
.refer-body {
    padding-bottom: 0rem !important;
}
.refer-body .top-heading .icon-wrapper svg {
    color: #455a64;
}
.refer-body .top-heading h1 {
    display: flex;
    align-items: center;
    margin-left: 1rem;
    color: #5b5b5b;
    font-size: 1.5rem;
}

.refer-body .get-free-ad-credit {
    color: #959595;
    font-size: 0.9rem;
    margin-top: 0.8rem;
}
.refer-friend {
    justify-content: center;
    border: 1px #03a9f4 solid;
    align-items: center;
    width: 300px;
    height: 50px;
    margin: auto;
    cursor: pointer;
    gap: 19px;
    background-color: #e1f5fe;
    border-radius: 7px;
}

@media (max-width: 1200px) and (min-width: 990px) {
    .refer-friend {
        justify-content: center;
        border: 1px #03a9f4 solid;
        align-items: center;
        width: 280px;
        height: 50px;
        margin: auto;
        cursor: pointer;
        gap: 19px;
        background-color: #e1f5fe;
        border-radius: 7px;
    }
}
@media (max-width: 989px) {
    .refer-friend {
        justify-content: center;
        border: 1px #03a9f4 solid;
        align-items: center;
        height: 50px;
        margin: auto;
        cursor: pointer;

        gap: 19px;
        background-color: #e1f5fe;
        border-radius: 7px;
    }
}
.ReferFriendConstent {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #01579b;
}
.m-b-30px {
    margin-bottom: 30px;
}
.refer-body .bottom-share {
    align-items: center;
    margin-top: 8px !important;
}
.gap-180px {
    gap: 180px;
}
.credit-free {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #959595;
}
.refer-body .bottom-share button {
    font-weight: bold;
    color: #03a9f4;
    font-size: 16px !important;
}
