.mission {
    background-color: white;
}
.mission-container {
    display: flex;
    padding: 1rem;
}
.mission-container .mission-title {
    font-size: 2.1rem;
    color: #01579b;
    font-weight: 500;
    font-family: Roboto;
    line-height: 4rem;
}

.col-padding {
    padding: 0px 14px;
}
.image-box-mission {
    margin-top: 15px;
}

.image-box-mission img {
    width: 598px;
    height: 598px;
}

.mission-title-v2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 54px;
    /* identical to box height */

    text-align: center;

    color: #01579b;
    margin-bottom: 10px;
}

.mission-points {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    /* or 167% */

    letter-spacing: 0.01em;

    color: #000000;
}

.conditions-apply {
    color: #a8a8a8;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
}

.mission .mission-title {
    line-height: 2.5rem;
    margin: 1rem;
    padding-left: 1rem;
}

.mission-desc p {
    padding-left: 1rem;
    margin: 1rem;
}
.mission-desc span {
    color: #e1dada;
    font-size: 0.7rem;
}

.mission-desc div p {
    font-size: 1.25rem;
    line-height: 1.8rem;
    word-wrap: break-word;
}

.mission-image center img {
    width: 100%;
    height: auto;
    border-radius: 0.5rem;
}
.join-button button {
    /* text-transform: capitalize !important; */
}
.mission-video {
    margin: 1rem;
    display: flex;
}
@media (max-width: 1024px) {
    .mission-container {
        flex-direction: column;
    }
}

@media (max-width: 768px) {
    .image-box-mission img {
        width: 500px;
        height: 500px;
    }
}

@media (max-width: 530px) {
    .image-box-mission img {
        width: 400px;
        height: 400px;
    }
}

@media (max-width: 430px) {
    .image-box-mission img {
        width: 300px;
        height: 300px;
    }
}

@media (max-width: 400px) {
    .mission h1 {
        font-size: 1.5rem;
    }
    .react-player {
        width: 100% !important;
        height: 220px !important;
    }
    .mission .mission-title {
        font-size: 25px;
        padding-left: 0;
    }
    .mission-container {
        flex-direction: column;
    }
    .mission-desc p {
        padding: 0 !important;
        font-size: 0.8rem;
    }
    .mission-container .mission-title {
        font-size: 21px;
    }
    .mission-video {
        margin: 0;
    }
}
