/* Scroll Bar */
::-webkit-scrollbar {
    width: 0;
}

.company-details {
    /* background: rgba(157, 195, 216, 0.23); */
    background-color: white;
    width: 100%;
    min-height: 100vh;
}

/* .MuiPaper-root {
    background-color: transparent !important;
} */

.logo {
    position: relative;
}

.logo img {
    position: absolute;
    left: 2rem;
    top: 2rem;
    height: 4rem;
    width: 4rem;
    cursor: pointer;
}

.buttons .continue-button button {
    padding: 0.6rem 2rem;
    min-width: 10rem;
    background: #067eed;
    border-radius: 8px;
    box-shadow: none;
    font-weight: 700;
    font-size: 0.8rem;
}

.buttons .continue-button button:hover {
    background: #0277bd;
}

.buttons .back-button button {
    padding: 0.6rem 2rem;
    min-width: 10rem;
    background: #909090;
    border-radius: 8px;
    box-shadow: none;
    font-weight: 700;
    font-size: 0.8rem;
}

.buttons .back-button button:hover {
    background: #737373;
}

@media (max-width: 768px) {
    .logo {
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .logo img {
        position: relative;
        left: 0;
    }
}
@media (max-width: 400px) {
    .buttons .continue-button button {
        padding: 10px;
    }
}
