.badge_container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 10px;
    background-repeat: no-repeat;
    background-size: contain;
    min-height: 350px;
    min-width: 200px;
    background-position: top;
    padding: 30px 80px 120px;
    margin: 20px auto;
    margin-top: 0;
}

.badge_container p {
    font-size: 22px;
}
.badge_container h3 {
    font-size: 22px;
}
