.forgot-password .form-group {
	margin: 1rem 0;
}
.forgot-password .form-group label {
	display: block;
	font-weight: 600;
	color: #707070;
}
.forgot-password .form-group input {
	display: block;
	width: 100%;
	padding: 0.5rem 0.8rem;
	outline: none;
	border: 1px solid #8d8d8d;
	border-radius: 0.2rem;
	margin: 0.5rem 0;
}

.button-group {
	display: flex;
	justify-content: end;
	margin-top: 2rem;
}

.button-group button:first-child {
	margin-right: 1rem;
}