@import url("https://fonts.googleapis.com/css2?family=Lobster&display=swap");

.footer {
    width: 100%;
    background-color: #3a3f42;
}
.footer-div {
    width: 100%;
    padding: 2rem;
}
.footer-list {
    color: white;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 600px !important;
}
.active1:hover {
    text-decoration: underline;
    cursor: pointer;
}
.active2:hover {
    text-decoration: underline;
    cursor: pointer;
}
.active3:hover {
    text-decoration: underline;
    cursor: pointer;
}
.active4:hover {
    text-decoration: underline;
    cursor: pointer;
}

.footer .footer-text {
    font-size: 1rem;
    text-align: center;
    color: white;
}

.logo {
    letter-spacing: 0.1em;
    font-weight: 600;
    font-size: 2rem;
}

.logo span:first-child {
    color: #03a9f4;
}

.logo span:last-child {
    color: #017eb8;
}

.menus {
    padding-top: 1rem;
}

.menus h4 {
    color: #b0bec5;
    font-size: 1.25rem;
}

.navs ul li {
    display: block;
    list-style: none;
    line-height: 1.75rem;
}

.navs ul li a {
    color: #eceef0;
}

.socials {
    display: flex;
    justify-content: flex-end;
    padding-right: 3rem;
    padding-top: 2rem;
}

.socials svg {
    margin: 0.5rem 1rem;
}
.socio {
    margin-left: 1rem;
    cursor: pointer;
}

.copyright-image {
    margin-top: 8px;
}

@media (max-width: 768px) {
    .socials {
        padding-top: 2rem;
        width: 250px;
        margin-left: auto !important;
        margin-right: auto !important;
        justify-content: space-between;
    }
    .footer-list {
        color: white;
        display: flex;

        justify-content: space-between;
        font-size: 10px;
    }
    .socials {
        padding: 1rem 1rem 2rem 1rem;
        margin-right: 0;
    }
}
@media (max-width: 450px) {
    .footer-list {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        height: 2rem;
        font-size: 10px;
        padding-left: 2rem;
    }
}
