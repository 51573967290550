.cookies-header{
    
text-align: center;
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 30px;
line-height: 20px;
letter-spacing: 0.01em;
color: #000000;

margin-bottom: 10px;

padding-top:25px;
}

.cookies-subheader{
    
    font-weight: 700;
    }
    

.p{
    font-size: 16px;
    
}
table, tr, td {
    border: 1px solid black;
    border-collapse: collapse;
    align-items: center;
    text-align: center;
    padding-left: 3px;
    margin-bottom: 10px;
  
   
  }
  .table-header{
    text-align: center;
  }

  .update-date{
    margin-top:70px;
    padding-top:40px ;
    font-size: 14px;
    margin-bottom: 0px;
    
  }