@import url("https://fonts.googleapis.com/css2?family=Lobster&display=swap");
nav {
    position: fixed;
    left: 0;
    top: 0;
    /* border-right: 0.1rem solid #7a7a7a; */
    min-height: 100vh;
    height: 100%;
    width: 100px;
    box-shadow: 0px 4px 12px 2px rgba(0, 0, 0, 0.15);
}

.nav-expanded {
    width: 300px;
}

main {
    margin-left: 100px;
}

.main-expanded {
    margin-left: 300px;
}

.main-section {
    padding: 0;
}

.home-section {
    width: 100% !important;
}

.other-header {
    min-height: 5.6rem;
    height: 5.6rem;
    background-color: #01579b;
    position: fixed;
    top: 0;
    left: 300px;
    width: calc(100% - 300px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.other-header h1 {
    color: white;
    font-weight: 400;
    font-size: 2rem;
    text-align: center;
    font-family: Lobster;
}

.other-expanded {
    left: 100px;
    width: calc(100% - 100px);
}

.header-expanded {
    width: calc(100% - 100px);
    left: 100px;
}

.header-height {
    height: 5.6rem;
}
.mobile-nav {
    display: none;
}
.mobile-menu {
    display: none;
}

/* Mobile Navbar */
.mobile-navbar {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 1000;
    background: rgba(96, 125, 139, 0.9);
}

.mobile-close {
    position: absolute;
    right: 1rem;
    top: 1rem;
    color: white;
    font-size: 2rem;
}

.mobile-nav-items {
    position: absolute;
    /* top: 4rem; */
    top: 0rem;
    left: 0;
    width: 100%;
    z-index: 10010;
    padding: 2rem 2rem;
}

/* Media Query */
@media (max-width: 1200px) {
    main {
        margin-left: 100px;
    }
    .main-expanded {
        margin-left: 100px;
    }
    .nav-expanded {
        width: 100px;
    }
    .header-expanded {
        width: calc(100% - 100px);
        left: 100px;
    }
    header {
        left: 100px;
        width: calc(100% - 100px);
    }
}

@media (max-width: 1000px) {
    main {
        margin-left: 100px;
    }
    .main-expanded {
        margin-left: 100px;
    }
    .nav-expanded {
        width: 100px;
    }
    .header-expanded {
        width: calc(100% - 100px);
        left: 100px;
    }
    header {
        left: 100px;
        width: calc(100% - 100px);
    }
    .other-header {
        justify-content: left;
        padding-left: 1rem;
    }
    .other-header h1 {
        font-size: 2rem;
    }
}

@media (max-width: 767px) {
    main {
        margin-left: 0;
    }
    nav {
        display: none;
    }
    .home-header {
        left: 0 !important;
        width: 100% !important;
        top: 5rem !important;
    }
    .other-header {
        left: 0 !important;
        width: auto !important;
        top: 3.5rem !important;
        position: unset !important;
    }
    .other-header h1 {
        font-size: 1.6rem;
    }
    .mobile-nav {
        display: block;
        padding: 0.75rem 1rem;
    }
    .mobile-menu {
        display: block;
        position: absolute;
        right: 1rem;
        top: 0.6rem;
        color: white;
        font-size: 2.5rem;
        z-index: 50;
    }
    .header-height {
        height: 5rem;
        display: flex;
        align-items: center;
    }
}
@media (max-width: 400px) {
    .other-header {
        position: absolute;
        margin-top: 1.5rem;
        margin-right: 0;
    }
    .home-section {
        overflow-x: hidden;
    }
}
