.change-container {
    width: 100%;
    display: flex;
    height: 100vh;
}

.change-container .change-left {
    height: 100vh;
    width: 45%;
}

.change-container .change-left img {
    height: 100vh;
    width: auto;
    object-fit: cover;
    position: fixed;
}

.change-container .change-right {
    height: 100vh;
    width: 55%;
    margin-top: 3rem;
}

.change-container .change-right img {
    cursor: pointer;
    width: 4rem;
}

.change-password-heading h1 {
    font-size: 2.5rem;
    text-align: center;
    margin-top: 2rem;
    font-weight: 700;
}

.change-container .change-right .change-right-form {
    margin-top: 2rem;
    padding: 0 7rem;
}

.change-container .change-right .change-right-form .form-group {
    width: 100%;
    display: block;
    margin: 1rem 0 0 0;
    position: relative;
}

.change-container .change-right .change-right-form .form-group .shared-email {
    font-size: 0.75rem;
    color: #707070;
}

.change-container
    .change-right
    .change-right-form
    .form-group
    .change-form-input
    svg {
    position: absolute;
    right: 1rem;
    top: 2.8rem;
    cursor: pointer;
}

.change-container .change-right .change-right-form .form-group label {
    display: block;
    font-weight: 600;
    color: #707070;
}

.change-container .change-right .change-right-form .form-group .error-input {
    border: 0.1rem solid rgb(204, 1, 1);
}

.change-container .change-right .change-right-form .form-group input {
    display: block;
    width: 100%;
    padding: 0.5rem 0.8rem;
    outline: none;
    border: 0.1rem solid #8d8d8d;
    border-radius: 0.2rem;
    margin: 0.5rem 0;
}

.change-container .change-right .change-right-form .form-group input:focus {
    border: 0.1rem solid #067eed;
}

.change-form .input-field svg {
    right: 1rem;
    top: 2.8rem;
    cursor: pointer;
}

.change-form .input-field .error-input {
    border: 0.1rem solid rgb(228, 10, 10);
}

.change-password-button button {
    box-shadow: none;
    background: #067eed;
    border-radius: 0.2rem;
}

.change-container
    .change-right
    .change-right-form
    .change-submit-button
    button {
    border: none;
    outline: none;
    padding: 0.5rem 2rem;
    font-size: 1rem;
    background: #039be5;
    box-shadow: none;
    border-radius: 8px;
    color: white;
    font-weight: bold;
    cursor: pointer;
}
.change-container .change-right .change-right-form .change-submit-button {
    justify-content: center;
    display: flex;
    margin-top: 2rem;
}

.change-container
    .change-right
    .change-right-form
    .change-submit-button
    button:hover {
    background: #0277bd;
}

.change-container
    .change-right
    .change-right-form
    .change-submit-button
    button:disabled {
    background: #676767;
}

.change-container
    .change-right
    .change-right-form
    .form-group
    .password-validation {
    /* position: absolute; */
    width: 100%;
    top: 2rem;
    left: 0;
    background-color: #f4f4f4;
    border: 0.25rem solid #f4f4f4;
    z-index: 10;
    padding: 1rem;
    border-radius: 0.25rem;
}

.change-container
    .change-right
    .change-right-form
    .form-group
    .password-validation
    p {
    line-height: 1.25rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 0.8rem;
    line-height: 1.25rem;
}

.change-container
    .change-right
    .change-right-form
    .form-group
    .password-validation
    p
    svg {
    /* position: absolute; */
    right: 1rem;
    top: 2.8rem;
    cursor: pointer;
}

@media (max-width: 400px) {
    .change-container .change-left {
        display: none;
    }
    .change-container .change-right {
        width: 100%;
    }

    .change-container .change-right .change-right-form {
        padding: 0 1rem;
    }
    .change-container .change-right .change-right-form .submit-button button {
        width: 100%;
    }
}
