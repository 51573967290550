.pay-main-card-panel {
    display: flex;
    height: 100vh;
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.pay-main-card-panel input:focus {
    border: 1px solid #067eed;
}
.payment-confirm {
    margin-top: 100px;
}

.payment-confirm-left-panel {
    height: 100%;
    width: 40%;
    margin-left: 3rem;
    padding: 1rem 3rem 0 0;
    border-right: 2px solid grey;
}
.payment-tick {
    height: 2rem;
    width: 2rem;
}
.payment-tick img {
    margin-left: 11rem;
    margin-top: 1rem;
}
.payment-confirm-card {
    border: 1px solid white;
    box-shadow: 0px 0px 12px 10px #0000001a;
    border-radius: 15px;
    height: 200px;
    width: 400px;
    margin-top: 4rem;
}
.payment-confirm-card-head {
    margin-top: 1rem;
    font-size: 30px;
    font-family: poppins;
    font-weight: bold;
    text-align: center;
}
.payment-confirm-card-body {
    text-align: center;
    font-size: 15px;
    margin-top: 1rem;
}
.payment-confirm-right-panel {
    width: 40%;
    margin-left: 3rem;
    padding: 1rem;
}

.payment-confirm-right-panel div {
    margin-top: 1rem;
}
.payment-confirm-detail-head {
    font-size: 18px;
}
.payment-amount {
    display: flex;
}
.payment-amount-value {
    flex: 2;
    margin-left: 4rem;
}
.payment-amount-text {
    flex: 1;
}
.payment-date {
    display: flex;
}
.payment-date-value {
    flex: 2;
    margin-left: 4rem;
}
.payment-date-text {
    flex: 1;
}
.payment-method {
    display: flex;
}
.payment-method-value {
    flex: 2;
    margin-left: 4rem;
}
.payment-method-text {
    flex: 1;
}
.pay-free-credit {
    display: flex;
}
.pay-free-credit-text {
    flex: 1;
}
.pay-free-credit-value {
    flex: 2;
    margin-left: 4rem;
}
.dashboard-button button {
    background: #067eed;
    color: #ffffff;
    font-size: 17px;
    font-weight: 700;
    border-radius: 0.5rem;
    width: 14rem;
    height: 2.5rem;
    margin-top: 3rem;
    padding: 2px;
    align-items: center;
    justify-content: center;
}
.dashboard-button {
    justify-content: start;
    display: flex;
}
@media (max-width: 768px) {
    .payment-confirm {
        margin-top: 20px;
    }
}

@media (max-width: 1200px) {
    .pay-main-card-panel {
        flex-direction: column;
        margin-top: 1rem;
    }

    .payment-confirm-left-panel {
        width: 100%;

        padding: 0;
        border: none;
    }
    .payment-confirm-right-panel {
        width: 100%;
        margin-left: 1rem;
    }
}

@media (max-width: 400px) {
    .pay-main-card-panel {
        flex-direction: column;
        margin-top: 1rem;
    }
    .payment-confirm-left-panel {
        width: 100%;
        margin-left: 0;
        padding: 0;
        border: none;
    }
    .payment-confirm-right-panel {
        width: 100%;
        margin-left: 1rem;
    }
    .dashboard-button button {
        width: 90%;
        margin: 1rem 1rem 1rem 0;
        height: 2.5rem;
    }
    .payment-confirm-card {
        margin: 1rem 0 0.5rem 0.6rem;
        width: 95%;
    }
}
@media only screen and (max-width: 768px) and (min-width: 767px) {
    .payment-confirm-image {
        margin-top: 85px;
        width: 400px;
        margin-left: 130px;
    }
}

@media (max-width: 506px) {
    .payment-confirm-image {
        width: 400px;
    }
    .payment-confirm-card {
        margin-right: 65px !important;
    }
}
@media only screen and (max-width: 700px) and (min-width: 506px) {
    .payment-confirm-image {
        width: 600px;
    }
}
