.home-logo {
    width: 100%;
    height: 5.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.disable {
    pointer-events: none;
    cursor: default;
    text-decoration: none;
    color: black;
}
.logo-shrinked span:first-child {
    -moz-transform: scale(-1, 1);
    -webkit-transform: scale(-1, 1);
    -o-transform: scale(-1, 1);
    -ms-transform: scale(-1, 1);
    transform: scale(-1, 1);
}
* .backbone-items {
    max-height: calc(100vh - 88px);
    overflow-y: scroll;
    margin-top: 1.5rem;
    padding: 0 0.5rem;
    gap: 0.7rem;
    display: flex;
    flex-direction: column;
    padding-bottom: 3rem;
}
.backbone-items-nav-title {
    margin-top: 5px;
    font-size: 10px !important;
    display: flex;
    justify-content: center;
    text-align: center !important;
}
.backbone-items-nav-title:hover {
    color: #0078be;
}

.backbone-items-nav-title .active {
    color: #0078be;
}
.backbone-nav-logo {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 1rem 0;
}
.nav-logo img {
    max-width: 40px;
}
.backbone-nav-logo img {
    height: 3.5rem;
}
.backbone-nav-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    transition: all 0.5s;
    margin-bottom: 5px;
    justify-content: center;
}

.notification-icon {
    position: relative;
}

.notification-unread {
    position: absolute;
    top: 0;
    right: 12px;
    font-size: 12px;
    background-color: #c11717;
    color: white;
    border-radius: 100px;
    width: fit-content;
    height: fit-content;
    padding: 2px 8px;
}

@media (max-width: 768px) {
    .backbone-nav-item {
        display: flex;
        flex-direction: row;
        padding: 4px 1rem;
        cursor: pointer;
        transition: all 0.5s;
        justify-content: center;
        margin-bottom: 0px;
    }

    .backbone-nav-item p {
        color: black;
        padding-left: 1rem;
        font-size: 1rem;
        display: flex;
        align-items: center;
    }
}
.backbone-nav-item:hover {
    background-color: transparent;
    border-radius: 0.5rem;
}

.backbone-active-link .backbone-items-nav-title {
    color: #0078be;
}

.backbone-active-link {
    background-color: transparent;
    font-weight: 600;
}

.backbone-active-link .icon-wrapper {
    background-color: #e1f5fe;
    border: 0.1rem solid #e1f5fe !important;
    border-radius: 50%;
}

.backbone-items-each span:active {
    color: #1084b9;
}

.backbone-active-link .icon-wrapper svg:hover {
    font-weight: bold;
}

.backbone-nav-item .icon-wrapper {
    color: #5c6f77;
    /* border: 1px solid #5c6f77;
    border-radius: 50%; */
    padding: 0.5rem;
    height: 2.5rem;
}
.backbone-nav-item .logo-wrapper {
    color: #5c6f77;
    border: 1px solid #5c6f77;
    border-radius: 50%;
    /* padding: 0.5rem; */
    /* height: 100%; */
    object-fit: contain;
    width: 40px;
    height: 40px;
}
.backbone-nav-item .logo-wrapper:hover img {
    width: 40px;
    height: 40px;
    opacity: 0.5;
    border: 0.1rem solid #e1f5fe !important;
}
.backbone-nav-item .logo-wrapper:hover {
    width: 40px;
    height: 40px;
    border: 0.1rem solid #e1f5fe !important;
}

.nav-logo {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}
.backbone-nav-item .icon-wrapper:hover {
    background-color: #e1f5fe;
    border: 0.1rem solid #e1f5fe !important;
    border-radius: 50%;
}

.backbone-nav-item svg {
    font-size: 1.3rem;
    color: #455a64 !important;
}

.wrap-button {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    cursor: pointer;
}

.title-shrinked {
    display: none !important;
}
.hide-expand-icons {
    display: none;
}

/* Mobile Nav */
.mobile-backbone-nav-item {
    display: flex;
    padding: 1.25rem 0;
    border-bottom: 1px solid #bdbdbd;
}
.mobile-backbone-nav-item .mobile-icon-wrapper {
    color: #5c6f77;
    border: 1px solid #5c6f77;
    background-color: #ececec;
    border-radius: 50%;
    padding: 0.5rem;
    height: 2.5rem;
    width: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mobile-backbone-nav-item .mobile-nav-title {
    display: flex;
    align-items: center;
    color: white;
    padding-left: 1rem;
    font-size: 1.5rem;
    letter-spacing: 0.05rem;
}

/* Media Query */
@media (max-width: 1200px) {
    .backbone-nav-item p {
        font-size: 0.8rem;
    }
    .backbone-nav-item svg {
        font-size: 1.25rem;
    }
}

@media (max-width: 1000px) {
    .backbone-nav-item p {
        display: none;
    }
    .backbone-nav-item svg {
        font-size: 1.25rem;
    }
}
