/* Industry Section */
.profile-industry-type-section {
    padding: 1rem;
}
.profile-industry-type-section {
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
    color: #5a5a5a;
    font-weight: 500;
}
.profile-industry-type-section .form-group select {
    width: 100%;
    border: 1px solid grey;
    padding: 0.65rem 1rem;
    border-radius: 0.4rem;
    color: #7c7c7c;
    outline: none;
    font-size: 0.9rem;
}

.profile-selected-industry {
    background-color: #e1f5fe;
    width: 100%;
    padding: 0.5rem 1rem;
    margin-top: 1rem;
    border-radius: 0.5rem;
}

.profile-modal .modal-text {
    color: #5a5a5a;
    font-weight: bold;
    font-size: 1rem;
    margin: 0 3rem;
}

@media (max-width: 991px) {
    .profile-industry-type-section {
        margin-bottom: 0;
    }
}
@media (max-width: 576px) {
    .profile-industry-type-section {
        width: 100vw;
        margin-left: -15px;
    }
}
@media only screen and (max-width: 767px) and (min-width: 577px) {
    .profile-industry-type-section {
        width: 95vw;
        margin-left: -15px;
    }
}
