.profile {
    padding: 0;
    max-width: calc(100%);
    margin: 0;
}
.banner-instructions {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 8px;
}
.upload-banner-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.profile-header {
    position: absolute;
    top: 0;
    left: 100px;
    background-color: white;
    height: 5rem;
    display: flex;
    align-items: center;
    padding: 0 2rem;
    color: #37474f;
    justify-content: space-between;
    width: calc(100% - 100px);
}

.profile-header button {
    background-color: #039be5;
    color: white;
    font-weight: 700;
    padding: 0.6rem 1rem;
    min-width: 10rem;
    border-radius: 0.5rem;
    margin-right: 2rem;
}

.profile-header h1 {
    font-size: 1.5rem;
    font-weight: 600;
}

.profle-header-img {
    display: flex;
    align-items: center;
}
.profle-header-img img {
    height: 24px;
    width: 24px;
    margin-left: 0.5rem;
}

.profile-images {
    position: relative;
}

.profile-background {
    width: calc(100%);
    box-shadow: 0px -2px 4px #c4c4c4;
    overflow: hidden;
}

.profile-background figure img {
    width: 100%;
    height: 300px;
    border-bottom-left-radius: 0.6rem;
    border-bottom-right-radius: 1rem;
}

.profile-pictures {
    position: absolute;
    width: calc(100% - 100px);
    display: flex;
    justify-content: center;
    left: 100px;
    top: 220px;
}
.profile-edit-icon {
    position: absolute;
    right: 5rem;
    font-size: 2.5rem;
    margin-top: 1rem;
    cursor: pointer;
}
.profile-pictures-blank {
    height: 100px;
}
.profile-pictures figure {
    padding: 0.3rem;
    background-color: white;
    border: 0.1rem solid rgb(224, 224, 224);
    border-radius: 50%;
    box-shadow: 0px 0px 12px 0px #00000040;
}
.profile-pictures figure img {
    border-radius: 50%;
    width: 155px;
    height: 155px;
}
.profile-content {
    width: 100%;
    width: calc(100%);
    display: flex;
    justify-content: center;
}
.profile-content .profile-names h3 {
    text-align: center;
    color: #555555;
    font-size: 2.25rem;
    font-weight: 700;
}
.profile-rating {
    display: flex;
    justify-content: center;
    margin: 1rem 0;
}
.profile-rating span {
    font-size: 2rem;
}
/* .horizontal-line {
    position: absolute;
    width: calc(100% - 100px);
    border-bottom: 0.1rem solid rgb(165, 165, 165);
    left: 100px;
    top: 643px;
} */
.profile-about {
    padding: 2rem;
    width: 100%;
    width: calc(100%);
    display: flex;
    justify-content: left;

    position: relative;
}
.profile-about-blank {
    height: 7.5rem;
}
.profile-about h4 {
    color: #555555;
    font-size: 1.5rem;
    font-weight: 600;
    padding-bottom: 1rem;
}

/* Profile Down */
.profile-down {
    width: 100%;
    min-height: 300px;
    display: flex;
}
.profile-down .profile-down-left {
    background-color: transparent;
    width: 60%;
    min-height: 300px;
    padding: 2rem;
}
.profile-down .profile-down-right {
    width: 40%;
    /* background-color: #eceff1; */
    min-height: 300px;
    padding: 2rem 6rem 2rem 2rem;
}
.profile-down .profile-down-left .left-info {
    background-color: white;
    width: 100%;
    min-height: 300px;
    border-radius: 1rem;
    border: 1px solid #cecece;
}
.profile-down .profile-down-right .member-since {
    border: 1px solid #6e6d6d;
    border-radius: 0.6rem;
    padding: 0.75rem 1rem;
    font-size: 1.25rem;
    text-align: center;
}
.profile-down .profile-down-right .member-since span {
    font-weight: bold;
}
.profile-basic-details-header {
    height: 2.75rem;
    background-color: #cfd8dc;
    position: relative;
}
.profile-down-details-edit {
    position: absolute;
    right: 1rem;
    top: 0.6rem;
    font-size: 1.5rem;
}
.profile-down .profile-basic-details {
    border: 1px solid #6e6d6d;
    border-radius: 0.6rem;
    margin-top: 2rem;
    overflow: hidden;
}
.profile-down .profile-basic-details-body {
    padding: 0 1rem;
}
.profile-down .profile-basic-details-body h4 {
    line-height: 2.4rem;
    padding-left: 1rem;
}
.profile-down .profile-basic-details-body h3 {
    line-height: 2.4rem;
    font-weight: 600;
}
.profile-basic-details-body-border {
    border-right: 1px solid #6e6d6d;
}

.hide-laptop {
    display: none;
}

.hide-mobile {
    display: block;
}

/* Modal */
.profile-modal .opacity-25 {
    background: rgba(96, 125, 139, 0.9) !important;
    opacity: 1 !important;
    left: 100px !important;
}

.profile-modal .pointer-events-auto {
    left: 100px !important;
}

.profile-modal .close-button button {
    background: #cfd8dc !important;
    border-radius: 1rem !important;
    color: #5a5a5a !important;
    min-width: 8rem !important;
    text-transform: capitalize !important;
}

.profile-modal .save-button button {
    background: #067eed !important;
    border-radius: 1rem !important;
    color: white !important;
    font-weight: bold !important;
    min-width: 9rem !important;
    text-transform: capitalize !important;
}

.profile-modal .modal-head {
    color: #5a5a5a;
    font-size: 1.25rem;
    font-weight: 600;
    min-width: 460px;
}
.profile-modal .modal-head span {
    color: #b0b0b0;
    font-weight: 400;
    font-size: 1rem;
}
.profile-modal .modal-input {
    width: 100%;
    background-color: #e6e6e6;
    border: 1px solid rgba(0, 0, 0, 0.2);
    margin: 1rem 0;
    border-radius: 0.75rem;
    outline: none;
}
.profile-modal .modal-input:focus {
    outline: none;
    border: 1px solid rgba(0, 0, 0, 0.2);
}
.profile-modal .mb-6 {
    margin-bottom: 0 !important;
}

/* Profile Modal */
.change-profile-logo {
    display: flex;
    margin: 2rem 0;
    min-width: 767px;
}
.change-profile-logo img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-right: 2rem;
}
.change-profile-logo div {
    display: flex;
    align-items: center;
}
.change-profile-logo div input {
    display: none;
}
.change-profile-logo div label {
    color: #067eed;
    border: 1px solid #8a8a8a;
    padding: 0.25rem 2rem;
    border-radius: 0.5rem;
}

.change-profile-banner {
    display: flex;
    margin: 2rem 0;
    min-width: 667px;
}
.change-profile-banner img {
    width: 300px;
    height: 100px;
    margin-right: 2rem;
    border-radius: 0.25rem;
}
.change-profile-banner .input-field {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}
.change-profile-banner div input {
    display: none;
}
.change-profile-banner div label {
    color: #067eed;
    border: 1px solid #8a8a8a;
    padding: 0.25rem 3rem;
    border-radius: 0.5rem;
    margin: 0.4rem 0;
    width: 14rem;
}
.change-profile-banner div button {
    color: #555555;
    border: 1px solid #8a8a8a;
    padding: 0.35rem 1rem;
    border-radius: 0.5rem;
    margin: 0.4rem 0;
    font-weight: bold;
    width: 14rem;
}

.dont-images img {
    width: 300px;
    height: 100px;
    border-radius: 0.5rem;
    cursor: pointer;
}
.company-name {
    margin: 2rem 0;
}
.company-logo h1 {
    color: #5a5a5a;
    font-weight: bold;
}
.company-name h1 {
    color: #5a5a5a;
    padding-bottom: 1rem;
    font-weight: bold;
}
.company-name input {
    border: 1px solid #8d8d8d;
    border-radius: 3px;
    color: #7c7c7c;
    outline: none;
}
.company-name input:focus {
    outline: none !important;
}
.company-about {
    margin: 2rem 0;
}
.company-about h1 {
    color: #5a5a5a;
    padding-bottom: 1rem;
    font-weight: bold;
}
.company-about textarea {
    border: 1px solid #8d8d8d;
    border-radius: 3px;
    color: #7c7c7c;
    outline: none;
}
.company-about textarea:focus {
    outline: none !important;
}
/* .my-2 {
    width: 200px;
} */

/* Feedback Modal */
.endorse-modal .feedback-text textarea {
    margin-top: 1.25rem;
    outline: none;
    border: 1px solid #c3c3c3;
    border-radius: 0.25rem;
    padding: 1rem;
    width: 40vw;
    margin-bottom: 1rem;
    resize: none;
}

.endorse-modal .feedback-header {
    color: #444444;
    font-size: 1.5rem;
    font-weight: 600;
}
.justify-content {
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    width: 700px;
}

.row-banner-message h2 {
    color: #5a5a5a;
    font-size: 17px;
    font-weight: bold;
    padding-bottom: 8px;
}
.row-banner-message span {
    color: #5a5a5a;
    font-size: 15px;
}

/* @media (max-width: 1400px) {
    .profile-down .profile-down-right {
        padding: 2rem 2rem 2rem 2rem;
    }
}

@media (max-width: 1200px) {
    .profile-down .profile-down-left {
        padding: 2rem 1rem;
    }
    .profile-down .profile-down-right {
        padding: 2rem 1rem;
    }
} */

@media (max-width: 1240px) {
    .profile-down .profile-down-right {
        padding: 2rem 1rem 2rem 1rem !important;
    }
}

@media (max-width: 991px) {
    .hide-laptop {
        display: block;
    }
    .hide-mobile {
        display: none;
    }
    .profile-mobile-margin {
        margin: 1rem 2rem;
    }
    .profile-top {
        /* border-radius: 0.5rem; */
        padding: 0px;
        border-bottom: 0.1rem solid rgb(165, 165, 165);
    }
    .profile-top-shadow {
        overflow: hidden;
        border-radius: 0.5rem;
        /* border: 1px solid #cecece; */
        /* box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.15); */
    }
    .profile-down {
        display: block;
    }
    .profile-down .profile-down-left {
        width: 100%;
    }
    .profile-down .profile-down-right {
        width: 100%;
    }
    /* .horizontal-line {
        display: none;
    } */
    /* .profile-about {
        width: 100%;
        padding: 1rem 1rem;
        border-top: 0.1rem solid #898989;
        box-shadow: none;
    } */
}

@media (max-width: 767px) {
    .change-profile-banner .input-field {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
    }
    /* .horizontal-line {
        left: 0px;
        width: 100%;
    } */
    .banner-instructions {
        margin-left: 0px;
    }
    .upload-banner-btn {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .profile {
        padding: 0;
    }
    .change-profile-banner {
        min-width: 576px;
    }
    .profile-background {
        height: 160px;
    }
    .profile-down .profile-down-left {
        padding: 0.5rem;
    }
    .profile-pictures {
        left: 0;
        width: 100%;
        top: 15.5rem;
    }
    .profile-pictures figure img {
        height: 100px;
        width: 100px;
    }
    .profile-header {
        top: 3rem;
        left: 0;
        width: 100%;
    }
    .profile-edit-icon {
        right: 3rem;
    }
    /* Modal */
    .profile-modal .opacity-25 {
        left: 0 !important;
    }
    .profile-modal .pointer-events-auto {
        left: 0 !important;
    }
    .profile-background figure img {
        object-fit: cover;
    }
}
@media (max-width: 576px) {
    .profile-top {
        /* padding: 0.5rem 1rem; */
    }
    .profile-down .profile-down-right {
        padding: 2rem 1rem;
    }
    .profile-mobile-margin {
        margin: 1rem 1rem;
    }
    .profile-background {
        height: 140px;
    }
    .profile-pictures {
        left: 0;
        width: 100%;
    }
    .profile-background figure img {
        object-fit: cover;
    }
}

@media (max-width: 400px) {
    .hide-laptop {
        display: block;
    }
    .hide-mobile {
        display: none;
    }
    .profile-mobile-margin {
        margin: 1rem 0.6rem;
    }

    .profile-header button {
        background-color: #039be5;
        color: white;
        font-weight: 700;
        padding: 0.6rem 1rem;
        min-width: 8rem;
        border-radius: 0.5rem;
        margin-right: 1.5rem;
    }
    .profile-top {
        /* padding: 0.5rem 0.6rem; */
    }
    .profile-modal .modal-head {
        min-width: 320px;
    }
    .change-profile-banner {
        flex-flow: column wrap;
    }
    .change-profile-banner .input-field {
        align-items: flex-start;
    }
    .justify-content {
        display: flex;
        align-items: flex-start;
        justify-content: space-around;
        width: 357px;
    }
    .row-banner {
        width: 50%;
    }
    .my-2 {
        justify-content: center;
    }
    .modal-form textarea {
        max-width: 100vw !important;
    }
    .modal-form input {
        max-width: 100vw !important;
    }
    .endorse-modal .p-6 {
        padding: 0rem;
    }
    .change-profile-logo div {
        flex-direction: column;
    }
    .profile-background figure img {
        object-fit: cover;
    }
}

.modal-wrapper > div > div {
    max-width: 100vw !important;
}

input {
    max-width: 85vw !important;
}
