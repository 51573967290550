.home-wrapper {
    margin-top: 120px;
}
.home_card {
    /* max-width: 325px; */
    background-color: #f2f2f2;
    border-radius: 10px;
    padding: 15px;
    height: 100%;
}
.home_card2 {
    background-color: #f2f2f2;
    border-radius: 10px;
    padding: 15px;
    height: 100%;
    width: 100%;
}
.post_btn {
    width: 70px;
    padding: 5px;
    text-align: center;
    margin-left: auto;
    border-radius: 25px;
    background-color: #0078be;
    color: white;
}
.company_name_column h4:hover {
    color: #0c8efd;
    text-decoration: underline;
    cursor: pointer;
}
.connect_button {
    background-color: #86b499;
    padding: 5px 8px;
    font-size: 14px;
    border-radius: 6px;
    color: white;
    &:hover {
        background-color: #7ca88f;
    }
}
.maybe_later_button {
    background-color: #deebff;
    padding: 5px 8px;
    font-size: 14px;
    color: black;
    border-radius: 6px;
    &:hover {
        background-color: #dae9ff;
    }
}
.slick-prev:before,
.slick-next:before {
    color: #252525 !important;
}

.slick-prev {
    left: -10px !important;
    z-index: 48;
}

.slick-next {
    right: -10px !important;
    z-index: 48;
}

@media (max-width: 575px) {
    .connect_button {
        padding: 5px;
        font-size: 12px;
    }
    .maybe_later_button {
        padding: 5px;
        font-size: 12px;
    }

    .home_card .company_name_column {
        max-width: 140px;
    }
}
