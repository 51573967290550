.verify-heading h1 {
    text-align: center;
    color: #067eed;
    font-weight: 600;
    font-size: 1.25rem;
}

.verify-account-heading {
    text-align: center;
    font-size: 2.5rem;
    color: #707070;
}

.verify-account-button {
    width: 100%;
    display: flex;
    justify-content: center;
    box-shadow: none;
}

.verify-account-button button {
    box-shadow: none;
    background: #067eed;
    border-radius: 0.2rem;
}

.verify-account-button button:hover {
    box-shadow: none;
    background: #0469c7;
}
