.home-header {
    min-height: 5.6rem;
    height: 22rem;
    background-color: #01579b;
    position: absolute;
    top: 0;
    left: 100px;
    /* margin-top: 100px; */
    width: calc(100% - 100px);
}
.home-header-blank {
    height: 17rem;
}
.services-main {
    margin-top: 100px;
}
select {
    /* -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: ""; */
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    background-image: url("data:image/svg+xml;utf8,<svg fill='gray' height='24' viewBox='0 0 21 15' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 5px;
    border: 1px solid #dfdfdf;
    border-radius: 2px;
    margin-right: 0.5rem;
    padding: 1rem;
    padding-right: 2rem;
}

.drop {
    height: 5.85px !important;
}

.refer-friend {
    box-shadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.1) !important;
}
.home-header .home-heading {
    font-family: "Lobster";
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 60px;
    margin-left: 60px;
    margin-top: 60px;

    color: #ffffff;
}
.home-header .home-heading h1 {
    margin: 3rem 0 2rem 0;
    color: white;
    font-weight: 700;
    font-size: 3rem;
    text-align: left;
    padding-left: 2.4rem;
    font-family: "Noto Serif";
}
.header-expanded {
    width: calc(100% - 100px);
    left: 100px;
}
.form-container {
    position: absolute;
    bottom: 2.6rem;
    padding: 1rem 2rem 1rem 4rem !important;
}
.header-form {
    width: 100%;
    display: flex;
}
.header-form input {
    padding: 0.8rem 1rem;
    width: 100%;
    border: none;
    outline: none;
    border-radius: 0.5rem;
}

.header-form input::placeholder {
    color: #7c7c7c;
}
.header-form button {
    justify-content: space-between;
    width: 100%;
    padding: 0.8rem 1rem;
    border-radius: 0.6rem !important;
}

.w-40 {
    width: 40%;
    padding: 0 0.5rem 0 0;
}
.w-20 {
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.w-20 .submit-button {
    width: 100%;
}
.w-20 .submit-button button {
    width: 100%;
    color: white;
    text-transform: capitalize;
    background-color: #4fc3f7;
    font-size: 1rem;
    padding: 0.5rem 0;
}
.w-20 .submit-button button:hover {
    background-color: #1eaaeb;
}

.header-form select {
    padding: 0.8rem 1rem;
    width: 100%;
    border: none;
    outline: none;
    border-radius: 0.5rem;
    color: #7c7c7c;
}

.home-illustration {
    height: auto;
    border-radius: 12px;
}

.home-services-section {
    width: 100%;
    height: auto;
}

.services-col {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
}
.service-divide {
    border-right: 1px solid #cecece;
}

.suggestion-container {
    z-index: 1000;
}

.suggestion-box {
    background-color: white;
}

.suggestion-box :hover {
    background-color: #eceff1;
}

@media (max-width: 1320px) and (min-width: 1200px) {
    .services-col {
        padding-right: 0.5rem !important;
        padding-left: 0.5rem !important;
    }
}

/* Pagination */
.backbone-pagination {
    padding-bottom: 3rem;
    /* border-bottom: 0.1rem solid #cecece; */
}
.backbone-pagination .d-flex .pagination-previous {
    background-color: #eceff1;
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    font-weight: bold;
    color: black;
}
.backbone-pagination .d-flex .pagination-previous:disabled {
    color: grey;
}

.backbone-pagination .d-flex .pagination-next {
    background-color: #eceff1;
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    font-weight: bold;
    color: black;
}

.backbone-pagination .d-flex .pagination-next:disabled {
    color: grey;
}

.backbone-pagination .d-flex .pagination-buttons button {
    margin: 0 0.5rem;
    color: #a4a4a4;
}

.backbone-pagination .d-flex .pagination-buttons .active-button {
    padding: 0.5rem;
    font-size: 1.15rem;
    color: #01579b;
    border-radius: 0.5rem;
    background-color: #eceff1;
}

.browse-services h1 {
    font-size: 1.5rem;
    font-weight: 600;
    color: #37474f;
}

.laptop-hide {
    display: none;
}

.services-row {
    margin-top: 34px;
}

.main-right-section {
    padding-left: 2.5rem;
}

@media (max-width: 1200px) {
    .header-expanded {
        width: calc(100% - 250px);
        left: 250px;
    }
}

@media (max-width: 1080px) {
    .services-main {
        margin-top: 50px;
    }
    .header-expanded {
        width: calc(100% - 100px);
        left: 100px;
    }
    .home-illustration {
        display: none;
    }
    .main-top-form {
        margin: auto !important;
        width: 80% !important;
    }
    .home-header-blank {
        height: 19rem;
    }
    .home-services-section {
        border-right: none;
    }
    .main-right-section {
        padding-left: 0rem;
    }
}

@media (max-width: 767px) {
    .services-main {
        margin-top: 20px;
    }
    .home-illustration {
        display: none;
    }
    .services-row {
        padding: 0rem 0.5rem;
        margin-top: 34px;
    }
    .mobile-hide {
        display: none;
    }
    .form-container {
        position: relative;
        padding: 1rem !important;
        margin-top: 10rem;
    }
    .w-40 {
        width: 50%;
    }
    .header-form .submit-button button {
        height: 100%;
        width: 3rem;
        padding: 0;
        display: flex;
        justify-content: center;
    }
    .header-form .submit-button {
        margin-left: 1rem;
    }
    .laptop-hide {
        display: block;
    }
    .home-header {
        height: 18rem;
    }
    .home-header-blank {
        height: 15rem;
    }
    .home-header .home-heading h1 {
        text-align: left;
        padding-left: 1rem;
        margin: 0 !important;
        margin-bottom: 1rem !important;
        font-size: 2rem;
    }
    .home-header .home-heading {
        font-family: "Lobster";
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 60px;
        margin-left: 60px;
        margin-top: 60px;

        color: #ffffff;
    }
    .services-col {
        padding-right: 1rem !important;
        padding-left: 1rem !important;
    }
}
.m-t-50px {
    margin-top: 10px;
}
.Setting-Icon {
    right: 20px;
    display: flex;
    justify-content: flex-end;
    position: relative;
    bottom: 60px;
}
@media (max-width: 767px) {
    .Setting-Icon {
        right: 183px;
        display: flex;
        justify-content: flex-end;
        position: relative;
        bottom: 145px;
    }
}
.input-letter {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #7c7c7c;
}
@media (max-width: 767px) {
    .input-letter {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #7c7c7c;
        position: relative;
        bottom: 100px;
    }
}
.Endorsements {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #01579b;
}
.Earn-Credit {
    font-family: "Poppins";
    font-style: normal;
    font-size: 20px;
    line-height: 30px;
    color: #3b3b3b;
    font-weight: 700;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}
.Advertise {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: #000000;
}
.Advertise-css {
    justify-content: center;
    border: 1px solid #0288d1;
    box-shadow: 0px 0px 16px 1px rgb(0 0 0 / 16%);
    border-radius: 7px;
    align-items: center;
    width: 300px;
    height: 50px;
    margin: auto;
    cursor: pointer;
    gap: 19px;
    border-radius: 7px;
    margin-bottom: 20px;
    margin-top: 20px;
}
@media (max-width: 1200px) and (min-width: 990px) {
    .Advertise-css {
        justify-content: center;
        border: 1px solid #0288d1;
        box-shadow: 0px 0px 16px 1px rgb(0 0 0 / 16%);
        border-radius: 7px;
        align-items: center;
        width: 280px;
        height: 50px;
        margin: auto;
        cursor: pointer;
        gap: 19px;
        margin-bottom: 20px;
        margin-top: 20px;
    }
    .Advertise {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        /* font-size: 14px; */
        line-height: 30px;
        color: #000000;
    }
    .EndorsementImg {
        margin-left: 10px !important;
    }
    .Endorsements {
        margin-right: 10px !important;
    }
}
@media (max-width: 989px) {
    .service-divide {
        border-right: 0px;
    }

    .Advertise-css {
        justify-content: center;
        border: 1px #03a9f4 solid;
        align-items: center;

        height: 50px;
        margin: auto;
        cursor: pointer;
        gap: 19px;
        border-radius: 7px;
        margin-bottom: 20px;
        margin-top: 20px;
    }
}
.border-right {
    border-right: 1px solid #cecece;
}
.m-t-34px {
    margin-top: 34px;
}
