.faqsSetting {
    width: 50%;
    margin-left: 50px;
}

.faqsSetting .MuiPaper-root {
    margin: 0.75rem 0;
    border-radius: 0.75rem !important;
    background: #ffffff;
    border: 1px solid #e2e2e2;
    border-radius: 10px;
    overflow: hidden !important;
    padding: 0.3rem 0;
    min-height: 54px;
    box-shadow: none !important;
}

.MuiTypography-body1 {
    font-family: "Poppins" !important;
}
.faqs-heading {
    font-size: 1rem;
    font-weight: 700 !important;
    color: #3f3f3f;
}

.faqs-answer {
    font-size: 14px;
    color: black !important;
}

.detailMainCss {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.invoice-accordion {
    margin-bottom: 10px;
    padding-right: 10px;
    width: 100%;
}
.detailCss {
    display: flex;
}
.detailCss.invoice {
    gap: 10px;
}
.detailInner {
    display: flex;
    gap: 10px;
    font-size: 14px;
}
.invoice-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.invoice-value {
    margin-left: 5px;
}
.invoice-value.link {
    color: #00aaf5;
}
.invoice-value.link:hover {
    text-decoration: underline;
}
.invoice .text {
    font-weight: 400 !important;
}
.invoice-link,
.invoice-date {
    font-size: 14px;
    display: flex;
}
.invoice-date {
    position: relative;
    line-height: 1;
}
.icon {
    font-weight: 400;
    color: #00aaf5;
    display: flex;
    flex-direction: column;
    gap: 6px;
    cursor: pointer;
}
.icon span {
    font-size: 12px;
    line-height: 1;
}
.buttonDetail {
    display: flex;
    justify-content: space-between;
    margin-top: 35px;
    margin-bottom: 15px;
}
.MuiAccordionDetails-root {
    border-top: 1px solid #e2e2e2;
    display: flex;
    flex-direction: column;
}
.Inputdetail {
    background: #f4f4f4;
    border-radius: 10px;
    width: 218px;
    height: 40px;
    padding: 5px;
    margin-bottom: 10px;
}
.button-password {
    display: flex;
    justify-content: space-between;
}

.password-validation {
    /* position: absolute; */
    width: 100%;
    top: 2rem;
    left: 0;
    background-color: #f4f4f4;
    border: 0.25rem solid #f4f4f4;
    z-index: 10;
    padding: 1rem;
    border-radius: 0.25rem;
}

.password-validation p {
    line-height: 1.25rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 0.8rem;
    line-height: 1.25rem;
}

@media (max-width: 767px) {
    .faqsSetting {
        width: 90%;
        margin: auto;
    }
    .button-password {
        flex-direction: column;
    }
    .buttonDetail {
        flex-direction: column;
    }
}

.promoCode {
    background: #e1f0fa;
    border-radius: 8px;
    text-align: center;
}
@media (max-width: 440px) {
    .detailCss {
        display: flex;
        flex-direction: column;
    }
}
