.profile-basic-details-header {
    height: 2.75rem;
    background-color: #cfd8dc;
    position: relative;
}
.profile-down-details-edit {
    position: absolute;
    right: 1rem;
    top: 0.6rem;
    font-size: 1.5rem;
    cursor: pointer;
}
.profile-down .profile-basic-details {
    border: 1px solid #6e6d6d;
    border-radius: 0.6rem;
    margin-top: 2rem;
    overflow: hidden;
}
.profile-down .profile-basic-details-body {
    padding: 0 1rem;
}
.profile-down .profile-basic-details-body h4 {
    line-height: 2.4rem;
    padding-left: 1rem;
}
.profile-down .profile-basic-details-body h3 {
    line-height: 2.4rem;
    font-weight: 600;
}
.profile-basic-details-body-border {
    border-right: 1px solid #6e6d6d;
}
.basic-details-form {
    min-width: 360px;
    margin-bottom: 1.5rem;
}
@media (max-width: 767px) {
    .profile-down .profile-basic-details {
        margin-top: 0;
        margin-bottom: 1rem;
    }
}

.email-id {
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 16px;
    padding-right: 4px;
}

@media (max-width: 1440px) {
    .email-id {
        width: 268px;
        height: 35px;
    }
}

.basic-details-form .form-group .location .button {
    background-color: transparent;
    outline: none;
    width: calc(100% / 3);
}

.basic-details-form .form-group .location .button:first-child {
    padding-right: 0.5rem;
}

.basic-details-form .form-group .location .button:last-child {
    padding-left: 0.5rem;
}

.basic-details-form .form-group .location .button input {
    display: none;
    outline: none;
}

.basic-details-form .form-group .location .button label {
    background-color: transparent;
    outline: none;
    height: 2.8rem;
    width: 100%;
    border: 1px solid #8a8a8a;
    border-radius: 0.25rem;
    text-align: center;
    line-height: 2.8rem;
    vertical-align: middle;
}

.basic-details-form .form-group .location .button .label-active {
    background-color: #253944;
    color: white;
}
.basic-details-form .form-group select:focus {
    border: 0.1rem solid #067eed;
}

.basic-details-form .form-group select {
    width: 100%;
    padding: 0.7rem 1rem;
    border: 0.1rem solid #8d8d8d;
    outline: none;
    border-radius: 0.25rem;
    background-color: transparent;
}
