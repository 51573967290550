.profile {
    padding: 0;
    max-width: calc(100%);
    margin: 0;
}

.profile-header h1 {
    font-size: 1.5rem;
    font-weight: 600;
}

.profile-images {
    position: relative;
}

.profile-background {
    width: calc(100%);
    box-shadow: 0px -2px 4px #c4c4c4;
    overflow: hidden;
}

.profile-background figure img {
    width: 100%;
    height: 300px;
    border-bottom-left-radius: 0.6rem;
    border-bottom-right-radius: 1rem;
}

.profile-picture {
    position: absolute;
    width: calc(100% - 100px);
    display: flex;
    justify-content: center;
    left: 60px;
    top: 220px; /* extra */
}

.profile-picture-login {
    position: absolute;
    width: calc(100% - 100px);
    display: flex;
    justify-content: center;
    left: 100px;
    top: 14rem;
    /* extra */
}

.profile-edit-icon {
    position: absolute;
    right: 5rem;
    font-size: 2.5rem;
    margin-top: 1rem;
}

.profile-picture-blank {
    height: 100px;
}

.profile-picture figure {
    padding: 0.3rem;
    background-color: white;
    border: 0.1rem solid rgb(224, 224, 224);
    border-radius: 50%;
    filter: drop-shadow(0px 0px 12px rgba(0, 0, 0, 0.25));
}

.profile-picture figure img {
    border-radius: 50%;
    width: 155px;
    height: 155px;
}

.profile-picture-login-blank {
    height: 100px;
}

.profile-picture-login figure {
    padding: 0.3rem;
    background-color: white;
    border: 0.1rem solid rgb(224, 224, 224);
    border-radius: 50%;
    filter: drop-shadow(0px 0px 12px rgba(0, 0, 0, 0.25));
}

.profile-picture-login figure img {
    border-radius: 50%;
    width: 155px;
    height: 155px;
}

.profile-content {
    width: 100%;
    width: calc(100%);
    display: flex;
    justify-content: center;
}

.profile-content .profile-name h3 {
    text-align: center;
    color: #555555;
    font-size: 2.25rem;
    font-weight: 700;
}

.profile-rating {
    display: flex;
    justify-content: center;
    margin: 1rem 0;
}

.profile-rating span {
    font-size: 2rem;
}

/* .horizontal-line {
    position: absolute;
    width: calc(100% - 100px);
    border-bottom: 0.1rem solid rgb(165, 165, 165);
    left: 100px;
    top: 643px;
} */

.horizontal-line-login {
    position: absolute;
    width: 100%;
    border-bottom: 0.1rem solid rgb(165, 165, 165);
    left: 100px;
    top: 500px;
}

.profile-about {
    padding: 2rem;
    width: 100%;
    width: calc(100%);
    display: flex;
    justify-content: flex-start;

    position: relative;
}

.profile-about-blank {
    height: 7.5rem;
}

.profile-about h4 {
    color: #555555;
    font-size: 1.5rem;
    font-weight: 600;
    padding-bottom: 1rem;
}

/* Profile Down */
.profile-down {
    width: 100%;
    min-height: 300px;
    display: flex;
    flex-direction: row;
}

.profile-down .profile-down-left {
    background-color: transparent;
    width: 60%;
    min-height: 300px;
    padding: 2rem;
}

.profile-down .profile-down-right {
    width: 40%;
    /* background-color: #eceff1; */
    min-height: 300px;
    padding: 2rem 6rem 2rem 2rem;
}

.profile-down .profile-down-left .left-info {
    background-color: white;
    width: 100%;
    min-height: 300px;
    border-radius: 1rem;
    border: 1px solid #cecece;
}

.profile-down .profile-down-right .member-since {
    border: 1px solid #6e6d6d;
    border-radius: 0.6rem;
    padding: 0.75rem 1rem;
    font-size: 1.25rem;
}

.profile-down .profile-down-right .member-since span {
    font-weight: bold;
}

.profile-basic-details-header {
    height: 2.75rem;
    background-color: #cfd8dc;
    position: relative;
}

.profile-down-details-edit {
    position: absolute;
    right: 1rem;
    top: 0.6rem;
    font-size: 1.5rem;
}

.profile-down .profile-basic-details {
    border: 1px solid #6e6d6d;
    border-radius: 0.6rem;
    margin-top: 2rem;
    overflow: hidden;
}

.profile-down .profile-basic-details-body {
    padding: 0 1rem;
}

.profile-down .profile-basic-details-body h4 {
    line-height: 2.4rem;
    padding-left: 1rem;
}

.profile-down .profile-basic-details-body h3 {
    line-height: 2.4rem;
    font-weight: 600;
}

.profile-basic-details-body-border {
    border-right: 1px solid #6e6d6d;
}

/* Social Links */
.profile-social-links {
    border: 1px solid #6e6d6d;
    border-radius: 0.6rem;
    margin-top: 2rem;
    overflow: hidden;
}

/* .profile-social-items {
    margin-bottom: 3rem;
} */

.profile-social-item {
    display: flex;
    align-items: center;
    min-height: 3.2rem;
    padding: 0.75rem 0;
    border-bottom: 1px solid #6e6d6d;
}

.profile-social-item .d-flex .profile-social-icon-wrapper {
    margin-left: 1rem;
    margin-right: 2rem;
    padding: 0.75rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #b3e5fc;
    border-radius: 0.6rem;
    height: 2.8rem;
}

.profile-social-item .d-flex .profile-social-icon-wrapper svg {
    font-size: 1.25rem;
}

.profile-social-icon-name {
    display: flex;
    align-items: center;
    color: #546e7a;
    font-size: 1rem;
    font-weight: 600;
}

/* Profile Business Leaders */
.profile-business-leads {
    border-radius: 0.6rem;
    margin-top: 2rem;
    overflow: hidden;
}

.profile-business-header {
    display: flex;
    justify-content: center;
    color: #3b3b3b;
    font-size: 1.15rem;
    font-weight: 600;
    padding: 1rem;
    border-bottom: 1px solid #6e6d6d;
}

/* .profile-business-persons {
    margin-bottom: 3rem;
} */
.profile-business-person {
    display: flex;
    align-items: center;
    min-height: 3.2rem;
    padding: 0.75rem 1rem;
    /* border-bottom: 1px solid #6e6d6d; */
}

.profile-business-person .d-flex figure {
    border-radius: 50%;
    overflow: hidden;
    margin-right: 2rem;
}

.profile-business-person .d-flex figure img {
    width: 72px;
    height: 72px;
}

.profile-business-person-details {
    display: flex;
    align-items: center;
}

.profile-business-person-details div h3 {
    color: #555555;
    font-weight: bold;
    line-height: 2rem;
}

.profile-business-person-details div h5 {
    color: #555555;
    line-height: 1.5rem;
}

.user-email-modal-label {
    color: #5a5a5a;
    font-size: 16px;
    font-weight: bold;
}

.user-profile-modal {
    display: flex;
}

.user-email-modal {
    height: 100px;
    width: 400px;
}

.user-email-modal-label {
    color: #5a5a5a;
    font-size: 16px;
    font-weight: bold;
}

.user-email-modal-button button {
    background: #039be5;
    color: #ffffff;
    font-size: 17px;
    font-weight: 700;
    border: none;
    border-radius: 0.5rem;
    padding: 1rem;
    width: 7.4rem;
    line-height: 0.25rem;
}

.user-email-modal-button {
    display: flex;
    justify-content: flex-end;
    margin-top: 1.5rem;
}

.user-email-modal-input input {
    border: 1px solid #d6d6d6;
    width: 400px;
    line-height: 2.5rem;
    margin-top: 0.5rem;
    padding-left: 15px;
    outline: none;
}

.user-email-modal-input input:focus {
    border: 1px solid #067eed !important;
    outline: none;
}

.proceed-model-body {
    height: 100px;
    width: 441px;
}

.proceed-model-button {
    display: flex;
    justify-content: flex-end;
    margin-top: 1.5rem;
}

.proceed-model-button button {
    background: #039be5;
    color: #ffffff;
    font-size: 17px;
    font-weight: 700;
    border: none;
    border-radius: 0.5rem;
    padding: 1rem;
    width: 7.4rem;
    line-height: 0.25rem;
}

.success-model-button {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
}

.success-model-button button {
    background: #039be5;
    color: #ffffff;
    font-size: 17px;
    font-weight: 700;
    border: none;
    border-radius: 0.5rem;
    padding: 1rem;
    width: 7.4rem;
    line-height: 0.25rem;
}

.rate-model-body {
    width: 500px;
    border-radius: 1rem;
}

.rate-model-body-button {
    display: flex;
    justify-content: flex-end;
    padding-right: 1rem;
}

.rate-model-body-button button {
    background: #039be5;
    color: #ffffff;
    font-size: 17px;
    font-weight: 700;
    border: none;
    border-radius: 0.5rem;
    padding: 1rem;
    width: 7.4rem;
    line-height: 0.25rem;
}

.rate-model-body-head {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #cfd8dc;
    line-height: 4rem;
    border-radius: 7px;
}

.rate-model-body-head p {
    color: #555555;
    font-size: 25px;
    font-weight: bold;
}

.rate-model-body-container {
    padding: 1rem;
}

.rate-model-body-input input {
    border: 1px solid #d6d6d6;
    width: 450px;
    line-height: 2.5rem;
    margin-top: 0.5rem;
    padding-left: 15px;
    outline: none;
}
.basic-rate-model-body-input input {
    width: 100%;
    border: 1px solid #d6d6d6;
    line-height: 2.5rem;
    margin-top: 0.5rem;
    padding-left: 15px;
    outline: none;
}

.rate-model-body-input input:focus {
    border: 1px solid #067eed !important;
    outline: none;
}
.basic-rate-model-body-input input:focus {
    border: 1px solid #067eed !important;
    outline: none;
}

.rate-model-body-textarea textarea {
    border: 1px solid #d6d6d6;
    width: 450px;
    line-height: 1.2rem;
    margin-top: 0.5rem;
    padding-left: 15px;
    outline: none;
    padding-top: 6px;
}
.basic-rate-model-body-textarea textarea {
    width: 100%;
    border: 1px solid #d6d6d6;
    line-height: 1.2rem;
    margin-top: 0.5rem;
    padding-left: 15px;
    outline: none;
    padding-top: 6px;
}

.rate-model-body-textarea textarea:focus {
    border: 1px solid #067eed !important;
    outline: none;
}

.rate-model-body-count {
    color: #7c7c7c;
}

.success-model-body {
    height: 140px;
    width: 480px;
}

.rate-model-body-star {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.basic-rate-model-body-star {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
}
.basic-rate-model-body-star .react-stars {
    overflow: hidden;
    position: relative;
    width: 100%;
    justify-content: space-evenly;
    display: flex;
}

.rate-model-body-error {
    display: flex;
    align-items: center;
    background: #ffecec;
    line-height: 1.4rem;
    border-radius: 4px;
    padding: 1rem;
    margin: 1rem 1rem 0rem 1rem;
    width: 450px;
}

.rate-title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-size: 24px;
}

.hide-laptop {
    display: none;
}

.hide-mobile {
    display: block;
}

.rate-modal-container .p-6 {
    padding: 0;
}

.user-profile-modal .opacity-25 {
    background: rgba(236, 239, 241, 0.8) !important;
    opacity: 1 !important;
    /* left: 100px !important; */
}

.company_name {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    position: relative;
    padding: 0 4rem;
}

.favourites-cont {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 25.5rem;
    right: 5rem;
    gap: 10px;
}

.favourites {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #0277bd;
    border-radius: 8px;
    gap: 10px;
    flex-direction: row;
    width: 271px;
    height: 52px;
    cursor: pointer;
}
.favourites-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    flex-direction: row;
    width: 40px;
    height: 52px;
    cursor: pointer;
}

.favourite-text {
    display: flex;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    color: #0277bd;
    cursor: pointer;
}

.button-not-radio-user {
    position: relative;
    left: 480px;
}

.action-icon-2 {
    position: absolute;
    left: 30px;
}
.action-icon-2-m {
    position: absolute;
    left: 30px;
    width: 100%;
    height: 100%;
}
.action-icon-2-m img {
    width: 100%;
    height: 100%;
}
.favourites-cont-mobile {
    display: none;
}
.action-icon-user-profile svg {
    font-size: 1.8rem;
    color: #007893;
    cursor: pointer;
}
.action-icon-user-profile {
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: #e1f5fe; */
    height: 2.5rem;
    width: 2.5rem;
    /* margin: auto; */
    padding: 0.5rem;
    border-radius: 0.6rem;
}

.newMember {
    min-width: 50px;
}
.company_profile_name{
    max-width: 800px;
}

@media (max-width:1500px){
    .company_profile_name{
        max-width: 600px;
    }
}
@media (max-width:1250px){
    .company_profile_name{
        max-width: 500px;
    }
}
.profile-top {
    /* border-radius: 0.5rem; */
    /* padding: 0.5rem 2rem; */
    border-bottom: 0.1rem solid rgb(165, 165, 165);
}
@media (max-width: 1024px) {
    .profile-down .profile-down-left {
        width: 100%;
    }
    .profile-down .profile-down-right {
        width: 100%;
    }
    .profile-down {
        flex-direction: column;
    }
}
@media (max-width: 1200px) {
    .favourites-cont {
        display: none;
    }
    .favourites-cont-mobile {
        position: absolute;
        display: flex;
        flex-direction: column;
        top: 24.5rem;
        right: 1rem;
        gap: 10px;
    }
    .profile-about {
        width: 100%;
        padding: 1rem;
    }
}
@media (max-width: 767px) {
    .button-not-radio-user {
        position: relative;
        left: 200px;
    }
    .hide-laptop {
        display: block;
    }

    .hide-mobile {
        display: none;
    }

    .profile {
        padding: 0;
        overflow: hidden;
    }

    .profile-top {
        padding: 0px;
        /* box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.15); */
        overflow: hidden;
        /* border-radius: 0.5rem; */
    }

    .profile-background {
        height: 200px;
    }

    .profile-picture {
        left: 0;
        width: 100%;
        top: 19rem;
    }

    .profile-picture figure img {
        height: 100px;
        width: 100px;
    }

    .profile-picture-login {
        left: 0;
        width: 100%;
        /* top: 19rem; */
    }

    .profile-picture-login figure img {
        height: 100px;
        width: 100px;
    }

    .profile-header {
        top: 5.2rem;
        left: 0;
        width: 100%;
    }

    .horizontal-line-login {
        display: none;
    }

    .profile-edit-icon {
        right: 3rem;
    }
}

@media (max-width: 400px) {
    .hide-laptop {
        display: block;
    }

    .hide-mobile {
        display: none;
    }

    .profile-header h1 {
        font-size: 1.2rem;
    }

    .profile-down .profile-down-right {
        padding: 2rem 1rem;
    }

    .user-email-modal-button {
        display: flex;
        justify-content: flex-end;
        margin-top: 1.5rem;
    }

    .user-email-modal {
        height: 100px;
        width: 300px;
    }

    .user-email-modal-input input {
        width: 300px;
        line-height: 2rem;
        margin-top: 0.5rem;
        padding-left: 10px;
    }

    .proceed-model-body {
        height: 125px;
        width: 300px;
    }

    .rate-model-body {
        width: 350px;
    }

    .rate-model-body-textarea textarea {
        width: 318px;
    }

    .rate-model-body-input input {
        width: 318px;
    }

    .rate-model-body-error {
        width: 318px;
    }

    .success-model-body {
        height: 150px;
        width: 320px;
    }

    .rate-model-body-button {
        padding-top: 2.2rem;
        padding-right: 0;
    }

    .user-profile-modal .opacity-25 {
        background: rgba(96, 125, 139, 0.9) !important;
        opacity: 1 !important;
        left: 0 !important;
    }
}
