.pagination-buttons button {
    margin: 0 0.5rem;
    color: #a4a4a4;
}

.pagination-buttons .active-button {
    padding: 0.5rem;
    font-size: 1.15rem;
    color: #01579b;
    border-radius: 0.5rem;
    background-color: #eceff1;
}
.pagination-pre {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
}

.service-tags-blog-card {
    border-top: 0.1rem solid #9f9f9f;
    margin-top: 5px;
    padding-top: 1.2rem;
    max-height: 6.2rem;
    overflow: hidden;
}

.service-tags-blog-card div {
    margin-bottom: 1.25rem;
    display: inline-block;
    margin-right: 0.75rem;
}

.service-tags-blog-card span {
    background-color: #03a9f4;
    border-radius: 1rem;
    padding: 0.4rem 1.25rem;
    color: white;
    text-align: center;
    font-size: 0.9rem;
}
@media (max-width: 768px) {
    .otherImg {
        /* max-width: 100%; */
        height: auto;
    }
}
@media only screen and (max-width: 1024px) and (min-width: 770px) {
    .Blog-card {
        margin-right: 0px;
        display: inline-block;
        font-size: 0.5rem;
    }
    .service-tags-blog-card span {
        margin-left: 18px;
    }
}
@media (min-width: 1025px) {
    .Blog-card {
        margin-right: 0px;
        display: inline-block;
        font-size: 0.5rem;
    }
    .service-tags-blog-card span {
        margin-left: 18px;
    }
}
