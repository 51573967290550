.policy-navbar {
    position: fixed;
    height: 5rem;
    top: 0;
    left: 0;
    
    width: 100%;
    margin-right: 0px;
    margin-left: 0px;
    background-color: white;
    padding: 1rem 2rem;
    padding-bottom: 25px;
    border-bottom: 20px solid #03A9F4;
    margin-bottom: 40px;
}
.policy-navbar-margin {
	margin-top: 2rem;
}

.policy-navbar .logo {
    letter-spacing: 0.1em;
    font-weight: 600;
    font-size: 2rem;
    cursor: pointer;
    margin-bottom: 10px;
    margin-top: -4px;
}

.policy-navbar .logo span:first-child {
    color: #03a9f4;
}

.policy-navbar .logo span:last-child {
    color: #017eb8;
}

.policy-navbar-hamburger {
    display: none;
}

.offcanvas.show,
.offcanvas-toggling {
    margin-top: 5rem;
    width: 100%;
}

.offcanvas-backdrop {
    opacity: 0 !important;
}

.offcanvas {
    background-color: rgba(96, 125, 139, 0.9) !important;
}

.menu-box {
    border-bottom: 0.1rem solid white;
}

.menu-box a {
    color: white;
    line-height: 3.6rem;
    font-size: 1.25rem;
}

@media (max-width: 576px) {
    .policy-navbar-buttons {
        display: none !important;
    }
    .policy-navbar-hamburger {
        display: block;
    }
    .policy-navbar .logo {
        font-size: 1.5rem;
    }
}
