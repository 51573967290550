.panel-container {
    display: flex;
    flex-direction: column;
    margin-top: 90px;
}

.advertisement-panel-banner {
    display: flex;
    min-height: 100px;
    position: relative;
    justify-content: center;
    width: 100%;
    /* height: 300px; */
    /* background-color: #cfe4ec; */
    opacity: 0.8;
}

.advertisement-panel-banner img {
    /* position: relative; */
    /* object-fit: cover; */
    /* top: 2rem; */
    /* width: 100%; */
}

.active-images {
    display: flex;
    flex-wrap: wrap;
    padding: 1rem;
    gap: 30px;
}
.active-images-h {
    max-height: 375px;
    overflow: hidden;
}

.panel-closed-images {
    display: flex;
    flex-wrap: wrap;
    padding: 1rem;
    gap: 30px;
}

.panel-closed-campaign {
    padding: 4rem;
}

.active-campaign {
    padding: 4rem;
}

.active-campaign b {
    color: #565656;
    font-size: 18px;
    font-weight: bold;
}

.panel-closed-campaign b {
    color: #565656;
    font-size: 18px;
    font-weight: bold;
}

.advertisement-panel-create-button {
    background-color: #03a9f4;
    border-radius: 5px;
    height: 3rem;
    color: white;
    width: 20rem;
    display: flex;
    justify-content: center;
    /* margin-top: 14rem; */
    padding: 0.5rem;
    font-weight: bold;
    font-size: 20px;
    position: absolute;
    bottom: 20px;
}

.advertisement-panel-create-button:hover {
    background-color: #0b94d4;
}

.active-image-head p {
    font-size: 19px;
    font-weight: bold;
    color: #565656;
}

.panel-closed-image-head {
    font-size: 19px;
    font-weight: bold;
    color: #565656;
}

.ads-imgs {
    width: 156px;
    height: 156px;
    border: 1px solid #c7c7c7;
    border-radius: 15px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ads-imgs:hover {
    box-shadow: 0px 0px 12px 2px rgba(0, 0, 0, 0.25);
}

.see-all-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #03a9f4;
}

.see-all-btn button {
    color: #03a9f4;
    font-size: 20px;
    font-weight: 500;
}

.ads-empty {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 900px) {
    .advertisement-panel-banner {
        width: 100%;
        /* height: 300px; */
    }

    .active-images {
        flex-flow: row wrap;
        padding: 6px;
        width: 90%;
    }

    .active-images img {
        margin: 1rem;
    }

    .panel-close-images {
        flex-flow: row wrap;
        padding: 6px;
    }
}
@media (max-width: 768px) {
    .panel-container {
        margin-top: 0px;
    }
}

@media (max-width: 400px) {
    .advertisement-panel-banner {
        width: 100%;
        /* height: 300px; */
    }

    .active-campaign {
        padding: 2rem;
    }

    .active-campaign p {
        font-weight: bold;
        font-size: 24px;
        color: #565656;
    }

    .active-image-head {
        display: flex;
        align-items: flex-start;
        margin-bottom: 15px;
    }

    .active-images {
        flex-flow: row wrap;
        padding: 6px;
        width: 100%;
        justify-content: center;
        align-items: center;
    }

    .active-images img {
        margin: 4px;
    }

    .panel-closed-images {
        flex-flow: row wrap;
        padding: 6px;
        width: 100%;
    }

    .panel-closed-images img {
        width: 45%;
        margin: 4px;
    }

    .panel-closed-campaign {
        padding: 2rem;
    }

    .panel-closed-campaign p {
        font-weight: bold;
        font-size: 24px;
        color: #565656;
    }

    .panel-closed-image-head {
        display: flex;
        align-items: flex-start;
    }

    .ads-imgs {
        width: 156px;
    }
}
