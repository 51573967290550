.profile-reviews {
    /* margin: 1rem; */
    margin-top: 1rem;
    /* border: 1px solid #cecece; */
    border-radius: 1rem;
    padding: 1rem;
}

.profile-reviews .profile-reviews-heading {
    color: #5a5a5a;
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 1rem;
}

.profile-reviews .profile-reviews-heading span {
    color: #5a5a5a;
    font-size: 0.9rem;
    font-weight: 500;
}

/* Reviews */
.profile-review-card {
    padding: 1rem;
    border: 1px solid #cecece;
    border-radius: 0.75rem;
    margin: 1rem 0;
}
.profile-review-card .top-section {
    display: flex;
}
.profile-review-card .top-section img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin-right: 1rem;
}
.profile-review-card .top-section .top-section-detail {
    display: flex;
    align-items: center;
}
.profile-review-card .top-section .top-section-detail div h3 {
    color: #555555;
    font-weight: 500;
    padding-bottom: 0.3rem;
    font-size: 1.25rem;
}
.see-all-reviews {
    color: #03a9f4;
    font-weight: bold;
    font-size: 14px;
}

@media (max-width: 1024px) {
    .profile-reviews {
        margin-top: 1rem;
        padding: 0.5rem;
    }
}

@media (max-width: 767px) {
    .profile-reviews {
        padding: 0.6rem;
        margin: 1rem 0 0 0;
    }
}
