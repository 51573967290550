.new-ob-backbone {
    padding: 1rem 0;
    background: rgba(236, 239, 241, 0.55);
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    margin: auto;
    max-width: 400px;
}

@media (min-width: 1200px) {
    .new-ob-backbone {
        background: rgba(236, 239, 241, 0.55);
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        padding: 1rem 0;

        width: 300px;
        margin: auto;
    }
}

.new-ob-backbone .new-heading {
    text-align: center;
    padding-bottom: 1rem;
    color: #3b3b3b;
    font-weight: 700;
    font-size: 1.25rem;
}

.new-card {
    padding: 1rem 0.75rem;
    border-top: 0.1rem solid #cecece;
}

.new-card .new-card-logo img {
    height: 2.6rem;
    width: 2.6rem;
    border-radius: 50%;
}

.new-card .new-card-title {
    display: flex;
    align-items: center;
}

.new-card .new-card-title h4 {
    padding-left: 8px;
    color: #555555;
    font-weight: bold;
    font-family: "Poppins";
    font-size: 1rem;
}

.new-card .new-card-title-body {
    font-size: 0.9rem;
    color: #404040 !important;
}

@media (max-width: 991px) {
    .new-ob-backbone {
        width: 100%;
        margin-left: auto !important;
        margin-right: auto !important;
        /* margin-right: 3rem; */
    }
}

@media (max-width: 500px) {
    .new-ob-backbone {
        padding: 2rem 0;
        margin: 2rem 0;
    }
}
.justify-content-space-between {
    justify-content: space-between;
}
