.home-blog {
    margin: 2rem 1rem;
    background-color: #eceff1;
    border-radius: 1rem;
    padding: 2rem;
}

.blog-title {
    margin-top: 2rem;
    text-align: center;
    color: #37474f;
    font-size: 2.25rem;
    font-weight: 700;
    margin-bottom: 3rem;
}

.object-cover {
    object-fit: cover;
}

.blog-card {
    margin: 0 1rem;
    border-radius: 1rem;
    overflow: hidden;
    background-color: white;
    min-height: 456px;
}

.blog-card figure img {
    width: 100%;
    height: 200px;
}

.blog-card .blog-content {
    padding: 1rem;
}

.blog-card .blog-content .blog-card-title {
    font-size: 1.25rem;
    font-weight: 600;
    padding-bottom: 1rem;
    color: #455a64;
    font-family: "Poppins";
}
.blog-card .blog-content .blog-read-more {
    color: #067eed;
    margin-top: 1rem;
}

.home-blog .blog-see-all {
    color: #067eed;
    text-align: right;
    margin-bottom: 10px;
}

.home-blog .blog-see-all-text {
    font-size: 20px;
    font-family: "Poppins";
}

/* Overrriding */
.react-multiple-carousel__arrow--left {
    left: 0 !important;
    background-color: #546e7a;
}

.react-multiple-carousel__arrow--right {
    right: 0 !important;
    background-color: #546e7a;
}

.react-multi-carousel-track {
    padding-bottom: 3rem;
}
.custom-dot-active {
    background-color: #067eed;
    height: 10px;
    width: 10px;
    border-radius: 100px;
    margin-right: 10px;
}

.custom-dot-notactive {
    background-color: #c4c4c4;
    height: 10px;
    width: 10px;
    border-radius: 100px;
    margin-right: 10px;
}

@media (max-width: 767px) {
    .home-blog-container {
        display: flex;
        flex-direction: column;
    }
    .home-blog {
        padding: 2rem 4rem;
        width: 85%;
        align-self: center;
    }
    .blog-see-all {
        padding-right: 10px;
    }
}

@media (max-width: 575px) {
    .home-blog {
        padding: 2rem;
    }
}

@media (max-width: 500px) {
    .home-blog {
        padding: 2rem 0;
        margin: 0;
    }
}
