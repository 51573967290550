.why {
    height: auto;
    width: 100%;
}
.why-v2 {
    height: auto;
    width: 100%;
}

.why-box {
    width: 100%;
    background-color: #eceff1;
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
    padding-left: 1.5rem;
}

.why-box-v2 {
    width: 100%;
}
.why-v2-title {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 54px;
    /* identical to box height */

    text-align: center;

    color: #01579b;
    margin-bottom: 30px;
}

.why div h1 {
    font-size: 2.3rem;
    color: #253944;
    font-weight: 600;
    line-height: 4rem;
    margin-bottom: 2rem;
    display: flex;
    padding-left: 2rem;
    font-family: Roboto;
}
.why-span {
    display: flex;
    justify-content: center;
}
.why-col {
    margin: 2rem 0;
}

.why-col .image-box img {
    width: 145px;
    height: 145px;
}

.why-col .image-box-2 img {
    width: 380px;
    height: 380px;
    border-radius: 10px;
}

.why-col h3 {
    text-align: center;
    margin-top: 2rem;
    font-size: 1.3rem;
    line-height: 2.5rem;
    padding: 0 2rem;
}
.why-col h3 b {
    font-weight: 600;
    font-family: Roboto;
}

.why {
    text-align: center;
    align-items: center;
    display: flex;
    font-size: 14px;
    font-weight: 400;
    line-height: 25px;
}

.image-box h3 {
    font-family: Roboto;
}

@media (max-width: 1200px) {
    .why-box {
        width: 100%;
        border-radius: 0;
        padding-left: 0;
    }
}

@media (max-width: 768px) {
    .why div h1 {
        padding-left: 0;
    }
}
