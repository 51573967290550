.faqs {
    width: 60%;
    margin: 5rem auto;
}

.faqs-heading {
    font-size: 1rem;
    font-weight: 700 !important;
    color: #3f3f3f;
}

.faqs-answer {
    font-size: 14px;
    color: black !important;
}

.faqs .faqs-title {
    color: #333d43;
    font-size: 2.5rem;
    font-weight: 700 !important;
    text-align: center;
    margin: 3rem 0;
    font-family: "Poppins" !important;
}

.faqs .faqs-sub-title {
    color: #333d43;
    font-size: 2rem;
    font-weight: 700;
    text-align: center;
    margin: 3rem 0 2rem 0;
}

.faqs .faq-contact-message {
    text-align: center;
}
.faqs .faq-contact-message button {
    color: #03a9f4;
    font-weight: bold;
}

.faqs .faq-contact-message a {
    text-align: center;
    color: #03a9f4;
    font-weight: 600;
}

.faqs .MuiPaper-root {
    background-color: #eceff1 !important;
    margin: 0.75rem 0;
    border-radius: 0.75rem !important;
    border: none !important;
    overflow: hidden !important;
    padding: 0.3rem 0;
    min-height: 54px;
    box-shadow: none !important;
}

.MuiTypography-body1 {
    font-family: "Poppins" !important;
}
.faq-modal-container .opacity-25 {
    background: #607d8b !important;
    opacity: 1 !important;
}

.faq-modal-container .border-0 {
    width: 800px;
    height: 540px;
    display: flex;
    justify-content: center;
}
.faq-modal-main-body {
    display: flex;
}
.faq-contact-heading {
    padding: 0 2rem;
    color: #253944;
    font-size: 3rem;
    font-weight: 500;
    font-family: Roboto;
}
.contact-part-hide-mobile {
    align-items: center;
    display: flex;
}
.save-button {
    display: flex;
    justify-content: end;
}

.save-button button {
    width: 7rem;
    margin-right: 2rem;
    background: #067eed;
}
.faq-contact-part {
    width: 480px;
    margin-left: 2rem;
    padding: 0 2rem;
}

@media (max-width: 880px) {
    .faqs {
        width: 75%;
    }
}

@media (max-width: 580px) {
    .faqs {
        width: 85%;
    }
}

/* Media Query */
@media (max-width: 400px) {
    .faqs {
        width: 90%;
    }
    .contact-part-hide-mobile {
        display: none;
    }
    .faq-modal-container .border-0 {
        width: 360px;
    }
    .faq-contact-part {
        margin: 0;
        padding: 0.5rem;
        width: 100%;
    }
    .save-button button {
        width: 7rem;
    }
    .faq-contact-heading {
        padding: 0.5rem 0.5rem 0 0.5rem;
    }
}
