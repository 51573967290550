.link-container {
    width: 300px;
    height: 350px;
    border: 1px solid #cecece;
    border-radius: 0.5rem;
}

.link-image {
    width: 100%;
    height: 65%;
    border-radius: 0.5rem 0.5rem 0rem 0rem;
    object-fit: cover;
}

.link-content {
    display: flex;
    flex-direction: column;
    padding: 15px;
}

.link-content h1 {
    color: #3b3b3b;
    font-size: 16px;
    font-weight: 600;
}

.link-content h2 {
    color: #5a5a5a;
    font-size: 12px;
    font-weight: 500;
}

.link-content p {
    color: #5a5a5a;
    font-size: 10px;
    font-weight: 400;
}
