.basic {
    display: flex;
    width: 90%;
    margin: auto;
    min-height: 70vh;
}
.basic-left {
    width: 40%;
    border-right: 0.1rem solid black;
    padding-right: 3rem;
}
.basic-right {
    width: 60%;
    padding: 1rem 3rem;
}

.maturity {
    display: flex;
}
.radinput {
    width: 8%;
}
.radio-input {
    border: 0px;
    width: 100%;
    height: 1.5rem;
}
.label-input {
    padding-left: 0.5rem;
}
.label-text {
    color: #707070 !important;
    font-weight: 400 !important;
}
.datalist {
    position: absolute;
    top: 5.35rem;
    left: 0;
    max-height: 150px;
    overflow-y: auto;
    padding: 1rem 0;
    width: 100%;
    background-color: white;
    border: 1px solid gray;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
}

.datalist p {
    padding: 0.8rem 1rem;
    cursor: pointer;
}

.datalist p:hover {
    font-weight: bold;
    background-color: rgb(226, 226, 226);
}

/* Textarea */
.basic-left .form {
    padding-top: 3rem;
}

.basic-left .form div {
    display: flex;
    justify-content: end;
}

.basic-left .form div textarea {
    resize: none;
    width: 100%;
    height: 219px;
    background-color: transparent;
    outline: none;
    border: 0.1rem solid #8d8d8d;
    padding: 1rem;
    border-radius: 0.5rem;
}
.basic-left .form .error-input {
    border: 0.1rem solid rgb(204, 1, 1);
}

.basic-left .form textarea:focus {
    border: 0.1rem solid #067eed;
}

.basic-left .form span {
    font-size: 0.75rem;
    color: #5a5a5a;
}

.basic-left .form label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: 600;
    color: #5a5a5a;
}

/* Company Form */
.basic-right .company-form .form-group label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: 600;
    color: #5a5a5a;
}

.basic-right .company-form .form-group input {
    width: 100%;
    padding: 0.7rem 1rem;
    border: 0.1rem solid #8d8d8d;
    outline: none;
    border-radius: 0.25rem;
    background-color: transparent;
}

.basic-right .company-form .form-group select {
    width: 100%;
    padding: 0.7rem 1rem;
    border: 0.1rem solid #8d8d8d;
    outline: none;
    border-radius: 0.25rem;
    background-color: transparent;
}

.basic-left .form .error-input {
    border: 0.1rem solid rgb(204, 1, 1) !important;
}

.basic-left.form textarea:focus {
    border: 0.1rem solid #067eed;
}

.basic-right .company-form .form-group .error-input {
    border: 0.1rem solid rgb(204, 1, 1) !important;
}

.basic-right .company-form .form-group input:focus {
    border: 0.1rem solid #067eed;
}

.basic-right .company-form .form-group select:focus {
    border: 0.1rem solid #067eed;
}

.basic-right .company-form .form-group .location .button {
    background-color: transparent;
    outline: none;
    width: calc(100% / 3);
}

.basic-right .company-form .form-group .location .button:first-child {
    padding-right: 0.5rem;
}

.basic-right .company-form .form-group .location .button:last-child {
    padding-left: 0.5rem;
}

.basic-right .company-form .form-group .location .button input {
    display: none;
    outline: none;
}

.basic-right .company-form .form-group .location .button label {
    background-color: transparent;
    outline: none;
    height: 2.8rem;
    width: 100%;
    border: 1px solid #8a8a8a;
    border-radius: 0.25rem;
    text-align: center;
    line-height: 2.8rem;
    vertical-align: middle;
}

.basic-right .company-form .form-group .location .button .label-active {
    background-color: #253944;
    color: white;
}

/* Media Query */
@media (max-width: 768px) {
    .basic {
        display: block;
    }
    .basic-left {
        width: 100%;
        border: none;
        padding-right: 0;
    }
    .basic-right {
        width: 100%;
        padding: 0;
    }
    .basic-left .company-logo label:first-child {
        width: 100%;
    }
    .basic-left .company-banner label:first-child {
        width: 100%;
    }
}

@media (max-width: 400px) {
    .button {
        font-size: 11px;
    }
    .btn {
        margin-right: 50px;
    }
}
@media only screen and (max-width: 992px) and (min-width: 769px) {
    .button {
        font-size: 11px;
    }
}
