.noti-container {
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 30px;
    margin: 100px auto;
}

.noti-headings {
    min-width: 194px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 45px;
    color: #565656;
    margin-top: 15px;
}

.divider {
    width: 683px;
    height: 0px;
    border-top: 1px solid #dbdbdb;
}

.noti-wrapper {
    width: 65%;
    height: auto;
    background: #ffffff;
    border: 1px solid #dbdbdb;
    border-radius: 8px;
}

.noti-category {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* height: 45px; */
    padding: 10px 16px;
    gap: 10px;
}

.icon {
    display: flex;
    justify-content: center;
    align-items: center;
}

.border-bot {
    border-bottom: 1px solid #dbdbdb;
}

.title {
    height: 33px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
}

.time {
    height: 18px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #adadad;
    display: flex;
    flex: 1 0 0;
    flex-direction: row-reverse;
}

.noti-heading-sub-title {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #565656;
}

@media (max-width: 1120px) {
    .noti-wrapper {
        width: 75%;
    }
}

@media (max-width: 960px) {
    .noti-wrapper {
        width: 100%;
    }

    @media (max-width: 770px) {
        .noti-heading-sub-title {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            color: #565656;
            margin-left: 58px;
        }

        .noti-container {
            margin-top: 40px;
        }
        .noti-headings {
            margin-top: 0;
            display: flex;
            margin-left: 20px;
        }
        .noti-heading-title {
            margin-left: 20px;
        }
        .noti-wrapper {
            margin-left: 20px;
        }

        .noti-category {
            justify-content: center;
        }
        .title {
            font-size: 14px;
            margin-left: 10px;
        }
        .time {
            display: none;
        }
    }
}
@media only screen and (max-width: 1024px) and (min-width: 770px) {
    .time {
        font-size: 10px;
    }
}

/* Custom CSS  */
.new_notification_container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.new_notification_tag {
    width: 55px;
    height: 55px;
    font-weight: 800;
    border-radius: 100%;
    margin-right: 15px;
    display: inline-block;
    background-color: red;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}
