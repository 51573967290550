.cont {
    height: 36rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 50px;
}

.back-btn button {
    width: 178px;
    height: 36px;
    background: #067eed;
    border-radius: 4px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #ffffff;
}

.error-msg{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}