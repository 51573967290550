.line {
    padding-top: 2rem;
    background-color: transparent;
}

.MuiStepper-root {
    background-color: transparent !important;
}

@media (max-width: 768px) {
    .MuiStepper-root {
        padding: 0 !important;
        margin-top: 3rem;
    }
}
