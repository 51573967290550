/* Request Other to endorse */
.request-other-to-endorse {
    width: 100%;
    overflow: hidden;
    margin-top: 1rem;
}
.chip-input-placeholder {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 20px;
    color: rgba(0, 0, 0, 0.54);
    pointer-events: none;
    z-index: 1;
  }
.request-other-to-endorse button {
    width: 100%;
    padding: 0.65rem 1rem;
    color: white;
    border-radius: 0.5rem;
    background-color: #0277bd;
    font-size: 20px;
}
.input-section .input-email {
    border-radius: 6px;
    border: 1px solid #cecece;
}
/* Modal */
.endorse-modal .opacity-25 {
    background: rgba(236, 239, 241, 0.8) !important;
    opacity: 1 !important;
}
.endorse-modal .mb-6 {
    margin-bottom: 0 !important;
}
.endorse-modal .endorse-body {
    width: 50vw;
}

.endorse-body .top-heading {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.endorse-body .top-heading .icon-wrapper {
    border: 1px solid #455a64;
    padding: 0.5rem;
    font-size: 1.6rem;
    border-radius: 50%;
}
.endorse-body {
    padding-bottom: 1rem;
}
.endorse-body .top-heading .icon-wrapper svg {
    color: #455a64;
}
.endorse-body .top-heading h1 {
    display: flex;
    align-items: center;
    margin-left: 1rem;
    color: #5b5b5b;
    font-size: 1.5rem;
}

.endorse-body .get-free-ad-credit {
    color: #959595;
    font-size: 0.9rem;
    margin-top: 0.8rem;
}

/* Chip Input */
.endorse-modal .WAMuiChipInput-inputRoot-2 {
    padding: 0 !important;
}
.endorse-modal .WAMuiChipInput-root-1 {
    border: none !important;
    min-width: 300px;
    padding: 0 !important;
    margin: 0 !important;
}

.endorse-modal .WAMuiChipInput-root-1 label {
    display: none;
}

.endorse-modal .WAMuiChipInput-root-1 input {
    padding: 0.3rem 1rem;
}

.endorse-modal .WAMuiChipInput-inputRoot-2.WAMuiChipInput-outlined-5 {
    padding-left: 0.5rem !important;
}

.endorse-modal .MuiChip-root {
    background-color: #29b6f6 !important;
    color: white;
    margin-bottom: 0.3rem;
}

.endorse-modal .MuiChip-root svg {
    color: white;
}

/* Text Area */
.endorse-body textarea {
    resize: none;
    background-color: #f0f5fa;
    padding: 1rem;
    border-radius: 0.75rem;
    width: 100%;
    outline: none;
}
.max-length-email-content {
    color: #ababab;
    font-size: 0.9rem;
    color: #5b5b5b;
    font-size: 0.9rem;
    text-align: justify;
}

/* Share Link */
.endorse-body .share-this-link {
    color: #5b5b5b;
    font-size: 1rem;
}

.endorse-body .bottom-share {
    align-items: center;
    margin-top: 2rem;
}

.endorse-body .bottom-share button {
    font-weight: bold;
    color: #03a9f4;
    font-size: 18px;
}

/* Buttons */
.endorse-modal .close-button button {
    background: #cfd8dc !important;
    border-radius: 0.6rem !important;
    color: #5a5a5a !important;
    min-width: 8rem !important;
    text-transform: capitalize !important;
}
.endorse-modal .save-button button {
    background: #067eed !important;
    border-radius: 0.6rem !important;
    color: white !important;
    font-weight: bold !important;
    min-width: 8rem !important;
    text-transform: capitalize !important;
}

@media (max-width: 767px) {
    .request-other-to-endorse {
        margin-top: 1rem;
    }
    .request-other-to-endorse button {
        display: flex;
        justify-content: center;
    }
    .endorse-modal .endorse-body {
        width: 60vw;
    }
}

@media (max-width: 575px) {
    .endorse-modal .endorse-body {
        width: 90vw;
    }
    .input-section {
        max-width: 90vw !important;
    }
}

@media (max-width: 400px) {
    .endorse-modal .endorse-body {
        width: 90vw;
    }
    .endorse-body {
        padding: 1.5rem;
    }
}

@media (max-width: 460px) {
    .endorse-modal .endorse-body {
        width: 96vw;
        padding: 12px;
    }
}
