.pricing-free-trial {
    width: 70%;
    background-color: #e1f0fa;
    border-radius: 0.3rem;
    padding: 0.8rem 1rem;
}

.pricing-free-trial p span {
    color: #067eed;
    font-weight: bold;
    cursor: pointer;
}

.free-top {
    font-size: 1.25rem;
    text-align: center;
}

.subscription {
    height: 5rem;
}

.subscription .subs-heading {
    text-align: center;
    color: #1b4b6c;
    font-size: 2.25rem;
    font-family: Roboto;
    font-weight: 700;
}

.subscription .details {
    text-align: center;
    color: #686868;
}

.terms-conditions p span {
    color: #067eed;
    font-weight: 600;
    cursor: pointer;
}

/* pricing-Plans */

.payment .pricing-plans {
    width: 100%;
    margin: 0 auto;
}

/* Plan Card */

.pricing-plan-card {
    width: 90%;
    height: 390px;
    border: 1px solid #5f5f5f;
    border-radius: 0.5rem;
    padding: 1rem 1.5rem 0.5rem 1.5rem;
    margin: 1rem 0;
}
.pricing-plan-card h1 {
    text-align: center;
    color: #7d7d7d;
    font-weight: 600;
    margin-bottom: 1rem;
}

.pricing-plan-card h2 {
    text-align: center;
    font-size: 2rem;
    font-weight: 600;
}

.pricing-plan-card h2 sub {
    color: #7d7d7d;
    font-weight: bold;
}

.pricing-plan-card .features {
    height: 262px;
    overflow-y: auto;
}

.pricing-plan-card .features p {
    color: #646464;
}

.pricing-plan-card div .coming-soon {
    height: 300px;
    max-height: 300px;
    padding: 3rem 0 2rem 0;
}

.pricing-plan-card div .coming-soon img {
    width: 70%;
}

.pricing-plan-card div .coming-soon-text h3 .text-center {
    color: #0e3854;
    font-size: 1.5rem;
    font-weight: 600;
    margin-top: 6rem;
}

.carousel .control-dots {
    margin: -5px 0 !important;
}

.carousel .control-dots li {
    background-color: black !important;
}
.tnc-modal-head {
    font-size: 1.5rem;
    font-weight: 600;
    display: flex;
    justify-content: center;
    margin-bottom: 3rem;
}
.tnc-modal-body {
    display: flex;
    justify-content: center;
    margin: 3rem;
}

.select-plan-button {
    margin-top: 1rem;
    width: 90%;
    margin-left: 1rem;
}
/* Media Query */
@media (max-width: 1200px) {
    .payment .pricing-plans {
        width: 100%;
    }
}

@media (max-width: 1000px) {
    .payment .pricing-plans {
        width: 100%;
    }
    .pricing-plan-card .features p {
        font-size: 0.75rem;
    }
}

@media (max-width: 768px) {
    .payment .pricing-plans {
        width: 80%;
    }
    .subscription .heading {
        font-size: 2rem;
    }
}

@media (max-width: 576px) {
    .payment .pricing-plans {
        width: 100%;
        margin-top: 2rem !important;
    }
    .subscription .heading {
        font-size: 1.5rem;
    }
    .tnc-modal-body {
        width: 190px;
    }
    .subscription .subs-heading {
        font-size: 1.5rem;
    }
}
