.payr-main-card-panel {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.payment-reject-left-panel {
    height: 90%;
    width: 40%;
    margin-left: 3rem;
    padding: 1rem 3rem 0 0;
}
.payment-reject-tick {
    height: 3rem;
    width: 3rem;
}
.payment-reject-tick img {
    margin-left: 13.5rem;
    margin-top: 1rem;
}
.payment-reject-card {
    border: 1px solid white;
    box-shadow: 0px 0px 12px 10px #0000001a;
    border-radius: 15px;
    height: 200px;
}
.payment-reject-card-head {
    margin-top: 0.5rem;
    font-size: 1.5rem;
    font-family: poppins;
    font-weight: bold;
    text-align: center;
}
.payment-reject-card-body {
    text-align: center;
    font-size: 1rem;
}
.payment-reject-right-panel {
    width: 40%;
    margin-left: 3rem;
    padding: 1rem;
}

.reject-retry-button button {
    background: #067eed;
    color: #ffffff;
    font-size: 17px;
    font-weight: 700;
    border-radius: 0.5rem;
    width: 15rem;
    height: 2.25rem;
    margin-top: 2rem;
    padding: 4px;
    align-items: center;
    justify-content: center;
}
.reject-retry-button {
    justify-content: center;
    display: flex;
}

@media (max-width: 400px) {
    .payr-main-card-panel {
        flex-direction: column;
        margin-top: 1rem;
    }
    .payment-reject-left-panel {
        width: 100%;
        margin-left: 0;
        padding: 0;
        border: none;
    }
    .reject-retry-button button {
        width: 90%;
        margin: 1rem 1rem 1rem 0;
        height: 2.5rem;
    }
    .payment-reject-card {
        margin: 1rem 0 0.5rem 0.6rem;
        width: 95%;
    }
}
