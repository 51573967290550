.login-container {
    width: 100%;
    display: flex;
    height: 100vh;
}

.login-container .login-left {
    align-items: center;
    height: 100vh;
    width: 50%;
}

.login-container .login-left img {
    height: 100vh;
    width: 100vh;
    object-fit: contain;
    /* position: fixed; */
}

.login-container .login-right {
    width: 45%;
    height: 100vh;
    margin-top: 3rem;
}

.login-container .login-right img {
    cursor: pointer;
    width: 4rem;
}

.logo-main {
    display: flex;
    margin-top: 0rem;
    justify-content: center;
}

.login-container .login-right h1 {
    font-size: 3rem;
    text-align: center;
    margin-top: 2rem;
    font-weight: 600;
}

.login-container .login-right .login-right-form {
    margin-top: 2rem;
    padding: 0 7rem;
}

.login-container .login-right .login-right-form .form-group {
    width: 100%;
    display: block;
    margin: 1rem 0 0 0;
    position: relative;
}

.login-container .login-right .login-right-form .form-group .shared-email {
    font-size: 0.75rem;
    color: #707070;
}

.login-container .login-right .login-right-form .form-group svg {
    position: absolute;
    right: 1rem;
    top: 2.8rem;
    cursor: pointer;
}

.login-container .login-right .login-right-form .form-group label {
    display: block;
    font-weight: 600;
    color: #707070;
}

.login-container .login-right .login-right-form .form-group .error-input {
    border: 0.1rem solid rgb(204, 1, 1);
}

.error-box {
    background: #ffecec;
    border-radius: 3px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height */

    color: #5b5b5b;
    padding: 12px 15px;
}

.login-container .login-right .login-right-form .form-group input {
    display: block;
    width: 100%;
    padding: 0.5rem 0.8rem;
    outline: none;
    border: 0.1rem solid #8d8d8d;
    border-radius: 0.2rem;
    margin: 0.5rem 0;
}

.login-container .login-right .login-right-form .form-group input:focus {
    border: 0.1rem solid #067eed;
}

.login-container .login-right .login-right-form .forgot-password {
    color: #067eed;
    font-size: 0.875rem;
    font-weight: 600;
    cursor: pointer;
}

.login-container .login-right .login-right-form .forgot-password:hover {
    text-decoration: underline;
    font-weight: 700;
}

.login-container .login-right .login-right-form .submit-button {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 2rem;
}

.login-container .login-right .login-right-form .submit-button button {
    border: none;
    outline: none;
    padding: 0.5rem 2rem;
    font-size: 1rem;
    background: #039be5;
    box-shadow: none;
    border-radius: 8px;
    color: white;
    font-weight: bold;
    cursor: pointer;
}

.login-container .login-right .login-right-form .submit-button button:hover {
    background: #0277bd;
}

.login-container .login-right .login-right-form .submit-button button:disabled {
    background: #676767;
}

.login-container
    .login-right
    .login-right-form
    .submit-button
    button:disabled:hover {
    background: #515151;
    cursor: not-allowed;
}

.login-container .login-right .login-right-form .dont-have-account {
    text-align: center;
    margin: 1rem 0 0.5rem 0;
    font-weight: 550;
}

.login-container .login-right .login-right-form .create-new-account {
    text-align: center;
    font-weight: 550;
    margin: 1rem 0;
    padding-bottom: 2rem;
}

.login-container .login-right .login-right-form .create-new-account span {
    color: #067eed;
    cursor: pointer;
}

.login-container .login-right .login-right-form .create-new-account span:hover {
    text-decoration: underline;
    font-weight: 600;
}

@media (max-width: 1072px) {
    .login-container .login-right .login-right-form {
        padding: 0 3rem;
    }
    .login-container .login-left {
        display: none;
    }
    .login-container .login-right {
        width: 100%;
    }
}

@media (max-width: 768px) and (min-width: 576px) {
    .login-container .login-right .login-right-form {
        padding: 0 2rem;
    }
    .login-container .login-right h1 {
        font-size: 2rem;
    }
    .login-container .login-right .login-right-form .form-group svg {
        position: absolute;
        right: 5rem;
        top: 2.8rem;
        cursor: pointer;
    }
}

@media (max-width: 576px) {
    .login-container .login-right .login-right-form {
        padding: 0 1rem;
    }
    .login-container .login-right .login-right-form .submit-button button {
        width: 100%;
    }
    .login-container .login-right .login-right-form .form-group svg {
        position: absolute;
        right: 1.9rem;
        top: 2.8rem;
        cursor: pointer;
    }
}
@media only screen and (max-width: 600px) and (min-width: 400px) {
    .login-container .login-right .login-right-form {
        margin-left: 15px;
    }
    .login-container .login-right .login-right-form .submit-button button {
        width: 200px;
    }
    .login-container .login-right .login-right-form .form-group svg {
        position: absolute;
        right: 2.9rem;
        top: 2.8rem;
        cursor: pointer;
    }
}
