.adss-card {
    overflow: hidden;
}

.adss-card img {
    border-radius: 1rem;
    width: 300px;
    margin: auto;
}

/* @media (max-width: 991px) {
    .tab-hide {
        display: none !important;
    }
} */
.advertisement-css {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #000000;
    margin-top: 20px;
    width: 300px;
    text-align: right;
}

@media (max-width: 989px) {
    .advertisement-css {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        /* identical to box height */

        color: #000000;
        /* margin-right: 127px; */
        margin-top: 20px;
    }
}
