.fav-card {
    width: 45rem;
    height: auto;
    border-radius: 0.75rem;
    box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.15);
    padding: 1.25rem 1.25rem 0.75rem 1.25rem;
    margin-right: 3rem;
    margin-bottom: 2rem;
}
.fav-wrapper {
    margin-top: 100px;
    padding: 40px 20px;
}
.favs-left {
    border-right: 0.1rem solid #9f9f9f;
    padding-right: 2rem !important;
}

.company_visit {
    text-decoration: none;
    color: inherit !important;
}

.top-layer {
    height: 4rem;
}

.top-layer .d-flex figure img {
    height: 3.25rem;
    width: 3.25rem;
    border-radius: 50%;
}

.top-layer .d-flex .fav-card-details {
    max-height: 4rem;
}

.top-layer .d-flex .fav-card-details h3 {
    font-size: 1.25rem;
    font-weight: bold;
    padding: 0 0 0.2rem 0;
}

.top-layer .d-flex .fav-card-details .reviews-count {
    color: #03a9f4;
    font-size: 1rem;
    font-weight: bold;
    padding-left: 0.5rem;
    font-size: 0.9rem;
}

.reviews-section {
    padding: 1rem 0;
    border-bottom: 0.1rem solid #9f9f9f;
}

.reviews-section .reviews-text {
    font-size: 0.85rem;
    color: #4e4e4e;
}

.reviews-section .review-by {
    font-weight: bold;
    color: #4e4e4e;
    font-size: 0.9rem;
}

.reviews-section h6 {
    color: #03a9f4;
    font-weight: 600;
    cursor: pointer;
    font-size: 0.9rem;
    margin-top: 0.6rem;
}

.fav-tags {
    padding-top: 1rem;
    max-height: 6.2rem;
    overflow: hidden;
}

.fav-tags div {
    margin-right: 0.5rem;
    margin-bottom: 1.25rem;
    display: inline-block;
}

.fav-tags span {
    background-color: #03a9f4;
    border-radius: 1rem;
    padding: 0.4rem 1.25rem;
    color: white;
    text-align: center;
    font-size: 0.9rem;
}

.action-items .d-flex {
    flex-direction: column;
    height: 100%;
    justify-content: space-around;
}

.action-items .d-flex .action-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e1f5fe;
    height: 2.5rem;
    width: 2.5rem;
    margin: auto;
    padding: 0.5rem;
    border-radius: 0.6rem;
}

.action-items .d-flex .action-icon svg {
    font-size: 1.8rem;
    color: #007893;
    cursor: pointer;
}

.company-card-name {
    display: flex;
}

.company-card-name h3 {
    padding: 0;
    margin: 0;
}
.company-card-name img {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0.6rem;
    height: 22px;
    width: 22px;
}

.favourite-comp-cont {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 100px 10px;
    flex-direction: column;
}

.header-form-fav {
    width: 100%;
    display: flex;
    border: 1px solid #acacac;
    border-radius: 0.6rem;
}
.header-form-fav input {
    padding: 0.8rem 1rem;
    width: 100%;
    border: none;
    outline: none;
    border-radius: 0.5rem;
}

.header-form-fav input::placeholder {
    color: #7c7c7c;
}
.header-form-fav button {
    justify-content: space-between;
    width: 100%;
    padding: 0.8rem 1rem;
    border-radius: 0.6rem !important;
}
.header-form-fav select {
    padding: 0.8rem 1rem;
    width: 100%;
    border: none;
    outline: none;
    border-radius: 0.5rem;
    color: #7c7c7c;
    /* font-size: small; */
}

/* Media Query */

@media (max-width: 767px) {
    .fav-wrapper {
        margin-top: 0px;
        padding: 20px;
    }
    .fav-card {
        padding-bottom: 1.25rem;
        max-width: 90vw !important;
    }
    .favourite-comp-cont {
        padding: 20px 0px;
    }
    .favs-left {
        border: 0px;
    }
    .services-left {
        border-right: none;
        padding-right: 0 !important;
    }
    .fav-tags {
        max-height: 9.2rem;
        border-bottom: 0.1rem solid #9f9f9f;
    }
    .action-items .d-flex {
        margin-top: 0.5rem;
        flex-direction: row !important;
    }
    .services-left {
        border-right: none;
        padding-right: 0 !important;
    }
    .fav-tags {
        max-height: 9.2rem;
        border-bottom: 0.1rem solid #9f9f9f;
    }
    .action-items .d-flex {
        margin-top: 0.5rem;
        flex-direction: row !important;
    }
    .header-form-fav .submit-button button {
        height: 100%;
        width: 3rem;
        padding: 0;
        display: flex;
        justify-content: center;
    }
    .header-form-fav .submit-button {
        margin-left: 1rem;
    }
}

@media (max-width: 991px) {
    .main-top-form {
        margin: auto !important;
        width: 100%;
    }
}
