.hero {
    padding: 3rem 2rem 2rem 2rem;
    width: 100%;
    margin-top: 1rem;
}
.hero .heading {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 68px;
    /* identical to box height, or 189% */

    letter-spacing: 0.01em;

    color: #01579b;
    margin-bottom: 20px;
}
.hero .description {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    /* or 156% */

    letter-spacing: 0.05em;

    color: #607d8b;
}

.hero .join-button {
    margin-top: 35px;
}
/* .hero .join-button button {
    text-transform: capitalize !important;
} */
.hero-illustration {
    padding: 2rem;
}
.lists {
    list-style: disc;
    padding-left: 1.5rem;
}
.hero-bg::after {
    content: "";
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-repeat: no-repeat;
    width: 100%;
    height: auto;
    background-size: contain;
    z-index: -1;
}
.join-button button {
    /* text-transform: capitalize !important; */
}

@media (max-width: 1400px) {
    .hero .heading {
        font-size: 2rem;
        line-height: 40px;
    }
    .hero .description {
        line-height: 25px;
    }
    .hero {
        padding: 2rem 2rem 2rem 2rem;
    }
}

@media (max-width: 1200px) {
    .hero {
        padding: 0rem 2rem 2rem 2rem;
    }
    .hero .heading {
        font-size: 2rem;
        line-height: 40px;
    }
    .hero .description {
        line-height: 25px;
    }
    .hero-background {
        padding: 2rem 0;
    }
}

@media (max-width: 1000px) {
    .hero {
        padding: 0;
    }
    .hero .heading {
        font-size: 2rem;
        line-height: 40px;
    }
    .hero .description {
        line-height: 25px;
    }
    .hero-illustration {
        padding: 0 !important;
    }
}

@media (max-width: 767px) {
    .hide-illustration {
        display: none;
    }
    .hero {
        padding: 0;
        height: 60%;
    }
    .hero .heading {
        margin-top: 0.5rem;
    }
    .hero-bg::after {
        display: none;
    }
    .join-button button {
        width: 100%;
    }
}

.hero-video > .react-player > video {
    width: 400px !important;
    height: 400px !important;
    object-fit: contain !important;
    border-radius: 10px;
}
.hero-video > .react-player {
    width: 400px !important;
    height: 400px !important;
    border-radius: 10px;
}

@media (max-width: 992px) {
    .hero-video {
        margin-top: 30px;
    }
}

@media (max-width: 430px) {
    .hero-video > .react-player {
        width: 300px !important;
        height: 300px !important;
        border-radius: 10px;
    }

    .hero-video > .react-player > video {
        width: 300px !important;
        height: 300px !important;
        object-fit: contain !important;
        border-radius: 10px;
    }
}
