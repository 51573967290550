/* Other Info */
.profile-all-other-info {
    padding: 1rem;
}
.profile-all-other-info-top {
    display: flex;
    justify-content: space-between;
}
.profile-all-other-info-top h1 {
    font-size: 1.25rem;
    color: #5a5a5a;
    font-weight: 500;
}
.profile-all-other-info-top .add-other-info {
    font-size: 2.4rem;
    color: #0277bd;
    cursor: pointer;
}
.all-other-info {
    border-radius: 0.75rem;
    border: 1px solid #cecece;
    overflow: hidden;
    margin-top: 0.5rem;
}
.all-other-info .grey-background {
    background-color: #cfd8dc;
    height: 3.5rem;
    width: 100%;
}
.all-other-info .title-section {
    padding: 1rem;
}
.all-other-info .title-section h5 {
    font-weight: bold;
    color: #5a5a5a;
    line-height: 2.5rem;
}

.all-other-info .title-section p {
    color: #4f4f4f;
}

.all-other-info .images-section-cont {
    display: flex;
}

.images-section-cont {
    display: flex;
    flex-direction: column;
}

.scroll-img {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
}

.images-section {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    white-space: nowrap;
    gap: 10px;
    padding: 0px 8px;
}

.pic-cont {
    height: 100px;
    width: 100px;
    cursor: pointer;
    position: relative;
    /* background: linear-gradient(220.6deg, #707070 23.01%, rgba(196, 196, 196, 0) 33.22%); */
}

.all-other-info .images-section figure {
    border-radius: 1rem;
    margin: 1rem 0.5rem;
    overflow: hidden;
}

.all-other-info .images-section figure img {
    height: 100px;
    width: 100px;
}

/* PDF Section */
.all-other-info .pdf-section {
    display: flex;
    padding: 0 15px;
}
.all-other-info .pdf-section-none {
    display: none;
    width: 0;
    height: 0;
    border-width: 0;
}

.all-other-info .pdf-section .each-pdf {
    border-radius: 0.5rem;
    margin: 1rem 0.5rem;
    overflow: hidden;
    height: 100px;
    width: 200px;
    position: relative;
}

.all-other-info .pdf-section .each-pdf img {
    height: 100px;
    width: 200px;
}

.all-other-info .pdf-section .each-pdf .pdf-banner {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2.5rem;
    background-color: #607d8b;
    color: white;
    display: flex;
    align-items: center;
}

.all-other-info .pdf-section .each-pdf .pdf-banner svg {
    font-size: 1.5rem;
    margin-left: 0.6rem;
}

.all-other-info .pdf-section .each-pdf .pdf-banner p {
    padding-left: 0.5rem;
}

/* Links Section */
.all-other-info .links-section {
    margin: 1rem;
}

.all-other-info .links-section li {
    margin: 0 1rem;
}

.all-other-info .links-section li:first-child {
    margin-left: 0 !important;
}

.each-link {
    /* width: 150px;
    height: 150px; */
    border-radius: 0.5rem;
    position: relative;
}

/* .each-link .react_tinylink_card div:first-child {
    width: 100%;
}

.each-link .react_tinylink_card div:last-child {
    display: none;
} */

.each-link figure img {
    border-radius: 0.5rem;
    height: 100px;
    width: 100%;
}

.each-link .link-banner {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2.5rem;
    background-color: #607d8b52;
    color: white;
    display: flex;
    align-items: center;
    font-size: 0.8rem;
    padding-left: 0.6rem;
}

.each-Plink {
    display: flex;
    width: 181px;
    height: 88px;
    background-color: #64808e;
    border: 1px solid #64808e;
    border-radius: 5px;
    flex-direction: row;
    cursor: pointer;
}
.each-svg {
    width: 25%;
    height: 100%;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    border-color: #64808e;
    position: relative;
}

.each-title {
    width: 75%;
    height: 100%;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}

.links-section .react-multi-carousel-track {
    padding-bottom: 0 !important;
}

.triangle {
    /* display: none !important; */
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 50px 50px 0;
    border-color: transparent #707070 transparent transparent;
    top: 0;
    position: absolute;
    margin-left: 50px;
    cursor: pointer;
}

.triangle1 {
    /* display: none !important; */
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 50px 50px 0;
    border-color: transparent #707070 transparent transparent;
    top: 0;
    position: absolute;
    left: 0;
    cursor: pointer;
}
.triangle2 {
    /* display: none !important; */
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 50px 50px 0;
    border-color: transparent #707070 transparent transparent;
    top: 0;
    position: absolute;
    right: 0;
    cursor: pointer;
}
.triangle1 {
    /* display: none !important; */
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 50px 50px 0;
    border-color: transparent #707070 transparent transparent;
    top: 0;
    position: absolute;
    margin-left: 130px;
    cursor: pointer;
}
.triangle2 {
    /* display: none !important; */
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 50px 50px 0;
    border-color: transparent #707070 transparent transparent;
    top: 0;
    position: absolute;
    margin-left: 142px;
    cursor: pointer;
}

/* Modal */
.profile-modal .add-other-box .top-bar {
    background-color: #cfd8dc;
    color: #5a5a5a;
    padding: 0.75rem 1rem;
    text-align: center;
    font-weight: 600;
    min-width: 320px !important;
}

.profile-modal .add-other-box .each-button {
    color: #5a5a5a;
    padding: 0.75rem 1rem;
    text-align: center;
    font-weight: 600;
    min-width: 320px !important;
    border-bottom: 1px solid #6e6d6d;
    cursor: pointer;
}
.profile-modal .add-other-box .each-button:last-child {
    border-bottom: none;
}

.profile-modal .add-other-box .each-button .icon-box {
    background-color: #b3e5fc;
    border-radius: 0.25rem;
    padding: 0.5rem;
}

.profile-modal .add-other-box .each-button .each-heading {
    font-size: 1rem;
}

.add-other-profile .p-6 {
    padding: 0 !important;
    border-radius: 1rem;
    overflow: hidden;
}

.img-div {
    width: 130px;
    height: 130px;
}

.delete > .delete-img {
    display: none;
    position: absolute;
    right: 10;
    top: 0;
}

.delete > .delete-img:hover {
    display: flex;
    position: absolute;
    top: 0;
    right: 10;
}

.delete-img-hide {
    display: none;
}

.delete-img-show {
    display: flex;
    position: absolute;
    top: 5px;
    right: 10px;
}
.delete-img-show-pdf {
    position: absolute;
    top: 5px;
    left: 160px;
}

.add-other-image {
    display: flex;
    justify-content: center;
}
.add-other-image {
    border: 1px solid grey;
    border-radius: 10px;
    height: 60px;
    width: 500px;
    margin: 0.5rem 0.5rem 1.5rem 0.5rem;
    background: #e6e6e6;
}
.add-other-image input {
    display: none;
}
.add-other-image label {
    background: #fafafa;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25);
    border: none;
    border-radius: 7px;
    height: 40px;
    width: 150px;
    margin: 0.5rem;
    padding: 0.5rem;
    color: #6c6c6c;
    font-weight: 600;
    text-align: center;
    font-size: 16px;
}
.other-image-span {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    color: #7c7c7c;
}

.add-other-pdf {
    border: 1px solid grey;
    border-radius: 10px;
    height: 60px;
    width: 500px;
    margin: 0.5rem 0.5rem 1.5rem 0.5rem;
    background: #e6e6e6;
}
.add-other-pdf input {
    display: none;
}
.add-other-pdf label {
    background: #fafafa;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25);
    border: none;
    border-radius: 7px;
    height: 40px;
    width: 150px;
    margin: 0.5rem;
    padding: 0.5rem;
    color: #6c6c6c;
    font-weight: 600;
    text-align: center;
    font-size: 16px;
}
.add-other-pdf {
    display: flex;
    justify-content: center;
}
.other-pdf-span {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    color: #7c7c7c;
}

.slide-cont {
    position: absolute;
    top: 18rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.slide-cont svg {
    width: 40px;
    height: 40px;
    cursor: pointer;
}

.slide-cont svg:hover {
    color: white;
}

.images-section::-webkit-scrollbar {
    height: 0.25rem;
    background-color: white;
    cursor: pointer;
    width: 0.5rem;
}

.carousel-img {
    width: 40rem;
    height: 40rem;
}

@media (max-width: 991px) {
    .profile-all-other-info {
        padding: 1rem 2rem;
        background-color: #eceff1;
        border-radius: 0.75rem;
        margin: 1rem 1rem 2rem;
        border: 1px solid rgb(171, 171, 171);
    }
    .all-other-info .links-section li {
        margin: 0 0.5rem;
    }
    .all-other-info .images-section {
        overflow-x: scroll;
    }
    .all-other-info .images-section figure img {
        height: 80px;
        width: 80px;
    }
    .each-link figure img {
        height: 80px;
    }
}

.react-horizontal-scrolling-menu--wrapper {
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 18px 0px;
}

.react-horizontal-scrolling-menu--scroll-container {
    flex-basis: 100%;
    flex-shrink: 0;
    gap: 10px;
}

/* Change position of container so arrows will be at top/bottom */
.react-horizontal-scrolling-menu--scroll-container {
    order: -1;
}

/* @media (max-width: 576px) {
    .profile-all-other-info {
        padding: 1rem 1rem;
        border-radius: 1rem;
    }
} */

@media (max-width: 575px) {
    .profile-all-other-info {
        margin: 1rem 0;
    }
}

@media (max-width: 400px) {
    .profile-all-other-info {
        padding: 1rem 0.6rem;
    }
    .add-other-image-container {
        width: 360px;
    }
    .add-other-image {
        width: 315px;
    }

    .add-other-pdf-container {
        width: 360px;
    }
    .add-other-pdf {
        width: 315px;
    }
    .all-other-info .images-section {
        overflow-x: scroll;
    }
    .all-other-info .images-section figure img {
        height: 80px;
        width: 80px;
    }

    .images-section::-webkit-scrollbar {
        height: 0.25rem;
        background-color: white;
        cursor: pointer;
        width: 0.5rem;
    }
    .images-section::-webkit-scrollbar-thumb {
        border-radius: 0.2rem;
        background-color: rgb(0, 7, 109);
    }

    .slide-cont {
        top: 9rem;
    }
    .carousel-img {
        width: 20rem;
        height: 20rem;
    }
    .links-section {
        width: 20rem;
    }
    .pdf-section {
        width: 22rem;
    }
    .images-section {
        width: 22rem;
    }
}
