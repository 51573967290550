.contact-part {
    padding: 0 2rem !important;
}
.contact-heading {
    color: #253944;
    font-size: 36px;
    font-weight: 600;
    font-family: "Poppins", "Roboto", "Segoe UI", "Oxygen", "Ubuntu",
        "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
}
.form-group {
    width: 100%;
}
.form-group label {
    display: block;
    color: #8d8d8d;
    font-weight: 600;
    line-height: 1.75rem;
}
.form-group input {
    width: 100%;
    padding: 0.6rem 1rem;
    display: block;
    border-radius: 0.25rem;
    border: 1px solid #8d8d8d;
    outline: none;
}
.form-group input:focus {
    border: 0.1rem solid #348ee1;
}

.form-group textarea {
    width: 100%;
    padding: 0.6rem 1rem;
    display: block;
    resize: none;
    border-radius: 0.25rem;
    border: 1px solid #8d8d8d;
    outline: none;
}
.form-group textarea:focus {
    border: 0.1rem solid #348ee1;
}

.submit-button button {
    padding: 0.75rem 2rem;
    box-shadow: none !important;
    text-transform: capitalize;
    font-size: 20px;
    font-weight: 700;
    width: 7rem;
    color: #067eed;
    height: 2.2rem;
    font-family: Roboto;
}
.submit-button button:focus {
    box-shadow: none !important;
}

@media (max-width: 767px) {
    .hide-mobile {
        display: none !important;
    }
    .contact-part {
        padding: 1rem !important;
    }
}
