.event-card .top .date-box h2 {
    font-size: 1.5rem;
    margin-bottom: 0;
    font-weight: bold;
    /* margin-top: 2px; */
}

.event-card .top .date-box span {
    text-transform: uppercase;
}

.event-card .top .date-box {
    text-align: center;
    height: 70px;
    width: 70px;
    background-color: #e1f5fe !important;
    border-radius: 0.75rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.event-card .event-title {
    font-size: 1.3rem;
    font-weight: bold;
}

.event-card .event-title span {
    font-weight: normal;
}

ModalHeader {
    color: #565656 !important;
}

.action-items .d-flex .action-icon.icon-danger {
    background-color: #faecec !important;
    color: #c11717 !important;
}

.card {
    border: none !important;
}

.event-card {
    margin-bottom: 20px;
    border-radius: 0.75rem;
    max-width: 800px;
}
.event-card.share-event .link p {
    float: left;
}
.event-card.share-event .link span {
    float: right;
}

.top {
    background: #ffffff !important;
    box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.15) !important;
    border-radius: 10px !important;
}

.eventModal {
    max-width: 602px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.borderIcon {
    border-radius: 50%;
    border: 1px solid;
    width: 50px;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.borderEnd {
    border-right: 1px solid rgba(159, 159, 159, 1);
}

@media (min-width: 761px) {
    .action-items {
        justify-content: center !important;
    }
}

@media (max-width: 760px) {
    .action-items {
        padding: 4px 0px;
        justify-content: space-between !important;
    }
    .borderEnd {
        border-right: none !important;
        border-bottom: 1px solid rgba(159, 159, 159, 1);
        padding-bottom: 15px;
    }
    .para {
        font-size: 11px;
        line-height: normal;
    }
    .span-event {
        line-height: normal;
    }
}
