.basic {
    display: flex;
    width: 90%;
    margin: auto;
    min-height: 70vh;
}
.basic-left {
    width: 40%;
    border-right: 0.1rem solid black;
    padding-right: 3rem;
}
.basic-right {
    width: 60%;
    padding: 1rem 3rem;
}
.skip-button {
    position: absolute;
    right: 2rem;
    top: 2rem;
}
.skip-button button {
    font-size: 1rem;
}

/* Logo */
.basic-left .company-logo div {
    display: flex;
    justify-content: end;
}
.basic-left .company-logo label:first-child {
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 60%;
    border-radius: 0.3rem;
    padding: 0.8rem 0.6rem;
    cursor: pointer;
}
.basic-left .company-logo label:last-child {
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 60%;
    border-radius: 0.3rem;
    padding: 0.8rem 0.6rem;
    cursor: pointer;
}

.basic-left .company-logo .browse-files {
    display: flex;
    justify-content: end;
}

.basic-left .company-logo .browse-files div {
    color: #067eed;
    font-weight: 600;
    padding: 0.5rem 0.8rem;
    margin-top: 0.8rem;
    border: 0.1rem solid #8a8a8a;
    border-radius: 0.5rem;
    cursor: pointer;
}

.basic-left .company-logo div label {
    color: #5a5a5a;
    font-weight: bold;
}

.basic-left .company-logo center {
    height: 10rem;
}

.basic-left .company-logo center .blank-image {
    height: 10rem;
    width: 10rem;
    background-color: #818181;
    border-radius: 50%;
}

.basic-left .company-logo img {
    width: 10rem;
    height: 10rem;
    border-radius: 50%;
}

.basic-left .company-logo input {
    display: none;
}

.basic-left .company-logo span {
    font-size: 0.75rem;
    color: #474747;
}

/* Company Form */
.basic-right .company-form .form-group label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: 600;
    color: #5a5a5a;
}

.basic-right .company-form .form-group input {
    width: 100%;
    padding: 0.7rem 1rem;
    border: 0.1rem solid #8d8d8d;
    outline: none;
    border-radius: 0.25rem;
    background-color: transparent;
}

.error-input {
    border: 0.1rem solid rgb(204, 1, 1) !important;
}

.basic-right .company-form .form-group input:focus {
    border: 0.1rem solid #067eed;
}

.basic-right .company-form .form-group select:focus {
    border: 0.1rem solid #067eed;
}

.basic-right .company-form .form-group span {
    font-size: 0.75rem;
    color: #5a5a5a;
}

.basic-right .company-form .form-group .services {
    width: 100%;
    margin-top: 1rem;
    display: inline-block;
}

.basic-right .company-form .form-group .services div {
    display: inline-flex;
    position: relative;
    padding: 0.3rem 0.5rem;
    margin: 0.2rem 0.2rem;
    border: 0.1rem solid #607d8b;
    border-radius: 0.5rem;
    background: #eceff1;
}

.basic-right .company-form .form-group .services div p {
    min-width: 5rem;
    color: #0e3854;
    font-weight: 500;
    padding-right: 1.5rem;
}

.basic-right .company-form .form-group .services div svg {
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    cursor: pointer;
}

/* Media Query */
@media (max-width: 1024px) {
    .basic-left .company-logo center .blank-image {
        height: 9rem;
        width: 9rem;
    }
    .basic-left .company-logo label:first-child {
        width: 90%;
    }
    .basic-left .company-logo span {
        padding-left: 0 !important;
    }
}
@media (max-width: 768px) {
    .basic {
        display: block;
        width: 100%;
    }
    .basic-left {
        width: 100%;
        border: none;
        padding-right: 0;
    }
    .basic-right {
        width: 100%;
        padding: 0;
    }
}
@media (max-width: 400px) {
    .basic-left .company-logo div label {
        width: 100%;
    }
    .basic-left .company-logo div label:first-child {
        width: 10rem;
    }
    .basic-left .company-logo div {
        justify-content: center;
    }
    .basic-left .company-logo div label:last-child {
        width: 100%;
    }
    .basic-left .company-logo .browse-files div {
        width: 100%;
    }
    .error-message-span {
        padding-left: 0 !important;
    }
}
@media only screen and (max-width: 600px) and (min-width: 400px) {
    .file-box {
        margin-right: 180px;
    }
    .browse-files {
        margin-left: 200px;
        margin-top: -250px;
    }
    .error-message-span {
        margin-left: 135px;
        margin-top: -80px;
    }
    .company-form {
        margin-left: 10px;
    }
}
@media only screen and (max-width: 800px) and (min-width: 601px) {
    .company-form {
        margin-left: 10px;
    }
    .basic-left {
        margin-right: 15px;
    }
}
