/* Reviews */
.profile-Allreview-card {
    padding: 1rem;
    align-items: center;
    border: 1px solid #cecece;
    border-radius: 0.75rem;
    margin: 1rem 1rem;
    width: 597px;
}

.all_reviews_heading {
    top: -139px;
}

.root_all_reviews {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 500px;
    margin: 50px auto;
    max-width: 60%;
}

.all_reviews_count {
    color: #5a5a5a;
    font-size: 0.9rem;
    font-weight: 500;
    float: right;
}

.allReviews h1 {
    font-size: 1.5rem;
    font-weight: 600;
    color: #37474f;
}

.review_all {
    display: flex;
    justify-content: center !important;
    flex-wrap: wrap;
}
.profile-Allreview-card .top-section {
    display: flex;
}
.profile-Allreview-card .top-section img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin-right: 1rem;
}
.profile-Allreview-card .top-section .top-section-detail {
    display: flex;
    align-items: center;
}
.profile-Allreview-card .top-section .top-section-detail div h3 {
    color: #555555;
    font-weight: 700;
    padding-bottom: 0.3rem;
    font-size: 1.25rem;
}
.see-all-reviews {
    color: #03a9f4;
    font-weight: bold;
    font-size: 14px;
}

@media (max-width: 768px) {
    .root_all_reviews {
        display: flex;
        justify-content: space-between;
        max-width: 500px;
        align-items: center;
        margin: 25px auto;
        max-width: 90%;
    }
}

@media (max-width: 600px) {
    /* Reviews */
    .profile-Allreview-card {
        width: 498px;
    }
    .root_all_reviews {
        width: 498px;
    }
}

@media (max-width: 500px) {
    /* Reviews */
    .profile-Allreview-card {
        width: 398px;
    }
    .root_all_reviews {
        width: 398px;
    }
}

@media (max-width: 400px) {
    .profile-reviews {
        padding: 0.6rem;
        margin: 1rem 0 0 0;
    }

    .profile-Allreview-card {
        width: 300px;
    }

    .review_all {
        padding: 1rem;
    }
}
@media only screen and (max-width: 767px) and (min-width: 600px) {
    .root_all_reviews {
        width: 598px;
    }
}
