/* Social Links */
.profile-social-links {
    border: 1px solid #6e6d6d;
    border-radius: 0.6rem;
    margin-top: 2rem;
    overflow: hidden;
}
/* .profile-social-items {
    margin-bottom: 3rem;
} */

.max-w-screen {
    max-width: 100vw !important;
}

.profile-social-item {
    display: flex;
    align-items: center;
    min-height: 3.2rem;
    padding: 0.75rem 0;
    border-bottom: 1px solid #6e6d6d;
    cursor: pointer;
    justify-content: space-between;
}

.add-social-item {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
    margin-top: 10px;
}

.profile-down-details-edit {
    position: absolute;
    right: 1rem;
    top: 0.6rem;
    font-size: 1.5rem;
    cursor: pointer;
}

.profile-social-item .d-flex .profile-social-icon-wrapper {
    margin-left: 1rem;
    margin-right: 2rem;
    padding: 0.75rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #b3e5fc;
    border-radius: 0.6rem;
    height: 2.8rem;
}
.profile-social-item .d-flex .profile-social-icon-wrapper svg {
    font-size: 1.25rem;
}
.profile-social-icon-name {
    display: flex;
    align-items: center;
    color: #546e7a;
    font-size: 1rem;
    font-weight: 600;
}
.profile-social-add-button {
    padding: 1rem;
}
.profile-social-add-button button {
    background: transparent;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    color: #546e7a;
    font-weight: 500;
    font-size: 1.25rem;
    width: 100%;
}
.profile-social-add-button button svg {
    color: #0277bd;
    font-size: 36px;
}

.profile-modal .select-social label {
    font-weight: bold;
    padding: 0.5rem 0;
}
.profile-modal .select-social input {
    width: 100%;
    outline: none;
    border: none;
    border: 1px solid rgb(172, 172, 172);
    border-radius: 0.75rem;
    padding: 0.5rem 1rem;
}
.link-modal-cont {
    height: 350px;
    overflow-y: scroll;
}
.d-flex:hover {
    text-decoration: none;
    /* color: transparent; */
    color: inherit;
}
.m-r-20px {
    margin-right: 20px;
}
.w-380-px {
    width: 380px;
}

/* ::-webkit-scrollbar {
	height: 0.25rem;
	background-color: white;
	cursor: pointer;
	width: 0.5rem;
} */

/* Track */
/* ::-webkit-scrollbar-track {
	border-radius: 0.25rem;
} */

/* Handle */
/* ::-webkit-scrollbar-thumb {
	border-radius: 0.2rem;
	background-color: rgb(0, 7, 109);
} */
