.testimonial {
    height: auto;
    width: 100%;
}

.testimonial-mobile {
    display: none;
}
.testimonial-laptop {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.testimonial-box {
    width: 100%;
    background-color: #eceff1;
}

.testimonial div h1 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.3rem;
    color: #253944;
    font-weight: 600;
    line-height: 2rem;
    display: flex;
    padding: 0 2rem 2rem 2rem;
    font-family: Roboto;
}
.testimonial-span {
    color: #000000;
    display: flex;
    justify-content: center;
    padding: 0 2rem 2rem 2rem;
    text-align: justify !important;
}
.testimonial-col {
    margin: 2rem 0;
    max-width: 100vw;
}

.testimonial-col .image-box img {
    width: 145px;
    height: 90px;
    object-fit: cover;
}

.testimonial-col h3 {
    text-align: center;
    margin-top: 2rem;
    font-size: 1.5rem;
    line-height: 0.5rem;
    padding: 0 2rem;
}
.testimonial-col h3 b {
    font-weight: 600;
    font-family: Roboto;
}

.testimonial {
    text-align: center;
    /* align-items: center; */
    /* display: flex; */
    font-size: 14px;
    font-weight: 400;
    line-height: 25px;
}

.image-box h3 {
    font-family: Roboto;
}
.left-arrow {
    display: flex;
    align-content: center;
    justify-content: center;
    width: 2rem !important;
}
.right-arrow {
    display: flex;
    align-content: center;
    justify-content: center;
    width: 2rem !important;
}

.testimonial-row {
    display: flex;
    flex-direction: row;
}
.arrow-image-map {
    display: flex;
    flex-direction: row;
}
.mx-4 {
    margin-left: 16px;
    margin-right: 16px;
}

.carousel-wrapper {
    max-width: 95vw;
}

@media (max-width: 992px) {
    .testimonial-mobile {
        display: block;
    }
    .testimonial-laptop {
        display: none;
    }
}

@media (max-width: 400px) {
    .mx-4 {
        margin: 0px;
    }

    .testimonial-box {
        width: 100%;
        border-radius: 0;
        padding-right: 0;
    }

    .testimonial-row {
        flex-direction: column;
    }
    .arrow-image-map {
        flex-direction: column;
    }
    .testimonial div h1 {
        padding-left: 0;
    }
}
