.profile-down .member-since {
    background: #eceff1;
    border: 1px solid #6e6d6d;
    border-radius: 10px;
    border-radius: 0.6rem;
    padding: 0.75rem 1rem;
    font-size: 1.25rem;
}
.profile-down .member-since span {
    font-weight: bold;
}

@media (max-width: 767px) {
    .profile-down .member-since {
        background-color: #eceff1;
    }
}
