.free-trial {
    width: 80%;
    background-color: #e1f0fa;
    border-radius: 0.3rem;
    padding: 0.8rem 1rem;
}

.free-trial p span {
    color: #067eed;
    font-weight: bold;
    cursor: pointer;
}

.free-top {
    font-size: 1.25rem;
    text-align: center;
}

.subscription {
    height: 5rem;
}

.subscription .details {
    text-align: center;
    color: #686868;
}

.terms-conditions p span {
    color: #067eed;
    font-weight: 600;
    cursor: pointer;
}

/* Plans */
.payment .plans {
    width: 85%;
    margin: 0 auto;
}

/* Plan Card */
.plan-card {
    height: 420px;
    border: 1px solid #5f5f5f;
    border-radius: 0.5rem;
    padding: 1rem 1.5rem 0.5rem 1.5rem;
    margin: 1rem 0;
    margin-top: 1rem;
}
.plan-card h1 {
    text-align: center;
    color: #7d7d7d;
    font-weight: 600;
    margin-bottom: 1rem;
}

.plan-card h2 {
    text-align: center;
    font-size: 2rem;
    font-weight: 600;
}

.plan-card h2 sub {
    color: #7d7d7d;
    font-weight: bold;
}

.plan-card .features {
    height: 262px;
    overflow-y: auto;
}

.plan-card button {
    width: 100%;
    box-shadow: none;
    background: #067eed;
}

.plan-card button:hover {
    box-shadow: none;
    background: #0468c5;
}

.plan-card .features p {
    color: #646464;
}

.plan-card div .coming-soon {
    height: 300px;
    max-height: 300px;
    padding: 3rem 0 2rem 0;
}

.plan-card div .coming-soon img {
    width: 80%;
}

.plan-card div .coming-soon-text h3 .text-center {
    color: #0e3854;
    font-size: 1.5rem;
    font-weight: 600;
    margin-top: 8rem;
}

.carousel .control-dots {
    margin: -5px 0 !important;
}

.carousel .control-dots li {
    background-color: black !important;
}
.tnc-modal-head {
    font-size: 1.5rem;
    font-weight: 600;
    display: flex;
    justify-content: center;
    margin-bottom: 3rem;
}
.tnc-modal-body {
    display: flex;
    justify-content: center;
    margin: 3rem;
}

.select-plan-button {
    margin-top: 1rem;
    width: 90%;
    margin-left: 1rem;
}

.subscription .heading {
    text-align: center;
    color: #0e3854;
    font-size: 2.5rem;
    font-weight: bold;
}

.coming-soon-text {
    margin-top: 8rem;
    color: #0e3854;
    font-weight: bold;
    font-size: 1.3rem;
}
/* Media Query */
@media (max-width: 1024px) {
    .coming-soon img {
        width: 80% !important;
    }
    .coming-soon-text {
        margin-top: 3rem;
    }
}

@media (max-width: 1000px) {
    .payment .plans {
        width: 100%;
    }
    .plan-card .features p {
        font-size: 0.75rem;
    }
    .backbone-para {
        margin-top: 20px;
    }
}

@media (max-width: 768px) {
    .payment .plans {
        width: 80%;
    }
    .subscription .heading {
        font-size: 2rem;
    }
}

@media (max-width: 576px) {
    .payment .plans {
        width: 100%;
        margin-top: 2rem !important;
    }
    .subscription .heading {
        font-size: 1.5rem;
    }
    .tnc-modal-body {
        width: 190px;
    }
    .subscription .subs-heading {
        font-size: 1.5rem;
    }
}

.subs-plan {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.subs-plan h1 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 54px;
    /* identical to box height */

    color: #0e3854;
}

.subs-plan p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    /* identical to box height */

    color: #686868;
}

@media (max-width: 768px) {
    .subs-plan h1 {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        /* identical to box height */

        color: #0e3854;
    }
    .logout {
        display: flex;
        align-items: center;
        margin-top: 20px;
        margin-bottom: 10px;
        justify-content: end;
    }
    .subs-plan p {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        /* identical to box height */

        color: #686868;
    }
}

.logout {
    display: flex;
    align-items: center;
    justify-content: end;
}

.logout-Div {
    background: #ffffff;
    border: 1px solid #00aaf5;
    border-radius: 10px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #00aaf5;
    padding: 5px 30px 5px 30px;
}
@media (max-width: 395px) {
    .backbone-para {
        font-size: 24px !important;
    }
}
