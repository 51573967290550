.btn-group button {
    margin-top: 10px;
    min-width: 150px;
}

.option {
    background-color: #f1f1f1;
    border-radius: 10px;
}

.success-modal h3 {
    font-size: 2.5rem;
    font-weight: bold;
}

.event-input {
    border-radius: 0.5rem !important;
}

.event-error-input {
    border: 1px solid #c11717 !important;
}

.modal-wrapper-addEvents > div > div {
    max-width: 100vw !important;
}

@media only screen and (min-width: 600px) {
    .modal-wrapper-addEvents > div > div {
        max-width: 80vw !important;
    }
}

@media only screen and (min-width: 798px) {
    .modal-wrapper-addEvents > div > div {
        max-width: 60vw !important;
    }
}

@media only screen and (min-width: 1300px) {
    .modal-wrapper-addEvents > div > div {
        max-width: 50vw !important;
    }
}
